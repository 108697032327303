import React from "react";
import { BaseComponent } from "../../base-component";
import NoAuthController from "../../controllers/noAuthController";
import Utilities from "../../utilities";
import "./style.css";

export default class Feedback extends BaseComponent {
  state = {
    message: "",
    sent: false,
    errors: {
      e: false,
      m: false
    }
  };

  feedback = async event => {
    event.preventDefault();

    let email = this.email.value;
    let cMessage = this.cMessage.value;

    let valid = true;
    let message = "";

    let errors = {
      e: !email,
      m: !cMessage
    };

    if (!email || !cMessage) {
      valid = false;
      message = "All fields are required";
    }

    if (valid && !Utilities.validateEmail(email)) {
      valid = false;
      message = "Email is not in the appropriate format";
      errors.e = true;
    }
    this.setState({ errors });

    if (valid) {
      const response = await NoAuthController.feedback(email, cMessage);

      if (response.success) {
        this.setState({
          message: response.error,
          sent: true
        });
      } else {
        this.setState({
          message: response.error
        });
      }
    } else {
      this.setState({
        message
      });
    }
  };

  render() {
    return (
      <div className="feedback_main">
        {!this.state.sent ? (
          <div>
            <div className="feedback_header">
              <h2>Give Us Feedback!</h2>
              The squeaky wheel gets the grease! Tell us what is annoying or
              what could be better, and we’ll fix it!
            </div>
            <div className="feedback_content">
              <input
                className={this.state.errors.e ? "input_error" : "input_normal"}
                ref={email => (this.email = email)}
                type="email"
                placeholder="email"
              />
              <textarea
                className={this.state.errors.m ? "input_error" : "input_normal"}
                ref={cMessage => (this.cMessage = cMessage)}
                type="email"
                placeholder="Write your thoughts here!"
              ></textarea>
              {this.state.message ? (
                <div className="feedback_message">{this.state.message}</div>
              ) : (
                ""
              )}
              <button onClick={this.feedback}>Submit</button>
            </div>
          </div>
        ) : (
          <div className="feedback_content">
            <h2>Thank you for your feedback!</h2>
            Your message has been sent and we will get back to you within 48
            hours.
          </div>
        )}
      </div>
    );
  }
}
