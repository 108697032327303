import React from 'react';
import { BaseComponent } from '../../base-component';
import { PlacesController } from '../../controllers/placesController';
import Utilities from '../../utilities';
import './style.css';

export default class ChangePassword extends BaseComponent {
    constructor(props) {
        super(props);
     
        this.state = {
          message: "",
          errors: {
            e: false,
            p: false
          }
        };

        this.controller = new PlacesController();
        this.changePassword = this.changePassword.bind(this);
      }

      async changePassword() {
        let password = this.password.value;
        let cdpassword = this.cdpassword.value;

        if(password && cdpassword) {
          if(password === cdpassword) {
            let response = await this.controller.changePassword(password);
            if(response.success) {
              this.setState({
                message: "Your password has been updated."
              });
            } else {
              this.setState({
                message: response.error
              });
            }
          } else {
            this.setState({
              message: "Passwords do not match."
            });
          }
        } else {
          this.setState({
            message: "Both fields are required."
          });
        }
      }
     
      render() {
        return (
          <div className="signin_main">
            <div className="signin_header">
              <h2>Change Password</h2>
            </div>
            <input className={this.state.errors.e ? "input_error": "input_normal"} ref={cdpassword => this.cdpassword = cdpassword} type="password" placeholder="password" />
            <input className={this.state.errors.p ? "input_error": "input_normal"} ref={password => this.password = password} type="password" placeholder="Confirm password" />
            {
              this.state.message ?
              <div className="signin_message">{this.state.message}</div>: ""
            }
            <button onClick={this.changePassword}>Change Password</button>
          </div>
        );
    }
}
