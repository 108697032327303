import React from "react";
import ReactDOM from "react-dom";
import { BaseComponent } from "../../base-component";
import "./style.css";
import markerIcon from "../../assets/marker.png";
import markerIconBad from "../../assets/marker_bad.png";
import marker_ok from "../../assets/marker_ok.png";
import Utilities from "../../utilities";

/**
 * 2pt​ Smooth ground service
    4pt​ Step free access
    1pt​ Designated accessible parking
    1pt​ Drop off area

    4pt​ Accessible table and seats
    3pt​ Spacious interior
    2pt​ Step free layout
    2pt​ Do not need stairs to access other floors
    3pt​ Accessible restrooms

    Outcome
    Easy: 19 points or more
    Medium: 15, 16, 17, or 18 points
    Not Recommended: 14 or fewer points
 */
export class Score extends BaseComponent {
  // componentDidMount() {
  //   // this.calculateScore();
  //   /* if(this.props.feature_sum !== undefined) {
  //         this.setState({
  //           feature_sum: this.props.feature_sum
  //         }, ()=>{
  //           this.calculateScore();
  //         });
  //       } */
  // }

  // componentWillReceiveProps(newProps) {
  //   // this.calculateScore();
  //   /* if(newProps.feature_sum !== undefined) {
  //         this.setState({
  //           feature_sum: newProps.feature_sum
  //         }, ()=>{
  //           this.calculateScore();
  //         });
  //       } */
  // }

  /**
       * Easy: 19 points or more
          Medium: 15, 16, 17, or 18 points
          Not Recommended: 14 or fewer points
       */
  // calculateScore = () => {
  //   let features = this.state.features;
  //       console.log("f", features);

  //       let score = Utilities.getScore(features); */
  //   // let score = this.state.feature_sum;
  //   // this.setState({
  //   //   outcome: marker
  //   // });
  //   // this.setState({
  //   //       outcome: score < 14 ? marker_bad : (score > 18 ? marker : marker_ok)
  //   //     }); */
  // }

  render() {
    const marker = this.props.accessible ? markerIcon : markerIconBad;

    return (
      <div className="score_wrapper">
        <img alt="travelez score" src={marker} />
      </div>
    );
  }
}
