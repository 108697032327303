import React from "react";
import { BaseComponent } from "../../base-component";
import NoAuthController from "../../controllers/noAuthController";
import Utilities from "../../utilities";
import "./style.css";

export default class ContactUsComponent extends BaseComponent {
  state = {
    message: "",
    sent: false,
    errors: {
      e: false,
      m: false
    }
  };

  handleContactUs = async event => {
    event.preventDefault();

    let email = this.email.value;
    let cMessage = this.cMessage.value;

    let valid = true;
    let message = "";

    let errors = {
      e: !email,
      m: !cMessage
    };

    if (!email || !cMessage) {
      valid = false;
      message = "All fields are required";
    }

    if (valid && !Utilities.validateEmail(email)) {
      valid = false;
      message = "Email is not in the appropriate format";
      errors.e = true;
    }

    this.setState({ errors });

    if (valid) {
      const response = await NoAuthController.contactUs(email, cMessage);

      if (response.success) {
        this.setState({
          message: response.error,
          sent: true
        });
      } else {
        this.setState({
          message: response.error
        });
      }
    } else {
      this.setState({
        message
      });
    }
  };

  render() {
    return (
      <div className="contactus_main">
        {!this.state.sent ? (
          <div>
            <div className="contactus_header">
              <h2>Contact Us</h2>
              Let us know what we can help you with.
            </div>
            <div className="contactus_content">
              <input
                className={this.state.errors.e ? "input_error" : "input_normal"}
                ref={email => (this.email = email)}
                type="email"
                placeholder="email"
              />
              <textarea
                className={this.state.errors.m ? "input_error" : "input_normal"}
                ref={cMessage => (this.cMessage = cMessage)}
                type="email"
                placeholder="What can we help you with?"
              ></textarea>
              {this.state.message ? (
                <div className="contactus_message">{this.state.message}</div>
              ) : (
                ""
              )}
              <button onClick={this.handleContactUs}>Contact Us</button>
            </div>
          </div>
        ) : (
          <div className="contactus_content">
            <h2>Thank you for contacting us!</h2>
            Your message has been sent and we will respond in less than 48
            hours!
          </div>
        )}
      </div>
    );
  }
}
