import React from "react";
import { BaseComponent } from "../base-component";

export class PlacesController extends BaseComponent {
  async search(
    search_text,
    sort_value,
    searchFilter,
    features,
    open_filter,
    page_num,
    cuisineFilter,
    inacessible,
    bounds
  ) {
    const response = await this.api.places.search({
      search_text,
      sort_value,
      searchFilter,
      features,
      open_filter,
      page_num,
      cuisineFilter,
      inacessible,
      bounds
    });

    console.log(response);
    //save token if success
    if (response.success) {
      return response;
    } else {
      return {
        success: false,
        error: response.error
      };
    }
  }

  async search_all() {
    let response = await this.api.places.search_all();
    console.log(response);
    //save token if success
    if (response.success) {
      return response;
    } else {
      return {
        success: false,
        error: response.error
      };
    }
  }

  async getFeatures() {
    let response = await this.api.places.getFeatures();
    console.log(response);
    //save token if success
    if (response.success) {
      return response;
    } else {
      return {
        success: false,
        error: response.error
      };
    }
  }

  async getCuisines() {
    let response = await this.api.places.getCuisines();
    console.log(response);
    //save token if success
    if (response.success) {
      return response;
    } else {
      return {
        success: false,
        error: response.error
      };
    }
  }

  async addFavorite(placeId, add) {
    let response = await this.api.places.addFavorite({ placeId, add });
    console.log(response);
    //save token if success
    if (response.success) {
      return response;
    } else {
      return {
        success: false,
        error: response.error
      };
    }
  }

  async getFavorites(date) {
    let response = await this.api.places.getFavorites(date);
    console.log(response);
    //save token if success
    if (response.success) {
      return response;
    } else {
      return {
        success: false,
        error: response.error
      };
    }
  }

  async addReview(placeId, review) {
    let response = await this.api.places.addReview({ placeId, review });
    console.log(response);
    //save token if success
    if (response.success) {
      return response;
    } else {
      return {
        success: false,
        error: response.error
      };
    }
  }

  async getReviews_Place(placeId) {
    let response = await this.api.places.getReviews_Place(placeId);
    console.log("here", response);
    //save token if success
    if (response.success) {
      return response;
    } else {
      return {
        success: false,
        error: response.error
      };
    }
  }

  async getReviews_User(page) {
    let response = await this.api.places.getReviews_User(page);
    console.log(response);
    //save token if success
    if (response.success) {
      return response;
    } else {
      return {
        success: false,
        error: response.error
      };
    }
  }

  async getAddedPlaces(date, page) {
    let response = await this.api.places.getAddedPlaces(date, page);
    console.log(response);
    //save token if success
    if (response.success) {
      return response;
    } else {
      return {
        success: false,
        error: response.error
      };
    }
  }

  async getUser() {
    let response = await this.api.places.getUser();
    console.log(response);
    //save token if success
    if (response.success) {
      return response;
    } else {
      return {
        success: false,
        error: response.error
      };
    }
  }

  async changePassword(password) {
    let response = await this.api.places.changePassword(password);
    console.log(response);
    //save token if success
    if (response.success) {
      return response;
    } else {
      return {
        success: false,
        error: response.error
      };
    }
  }

  async saveProfile(nickname, situation, publicOn, stairOptions, stairs, city) {
    let response = await this.api.places.saveProfile({
      nickname,
      situation,
      publicOn,
      stairOptions,
      stairs,
      city
    });

    console.log(response);
    //save token if success
    if (response.success) {
      return response;
    } else {
      return {
        success: false,
        error: response.error
      };
    }
  }

  async searchGoogle(text) {
    let response = await this.api.places.getGoogleSearch(text + " New York");

    console.log(response);
    //save token if success
    if (response.success) {
      return response;
    } else {
      return {
        success: false,
        error: response.error
      };
    }
  }

  async addPlace(data) {
    const response = await this.api.places.addPlace(data);

    //save token if success
    if (response.success) {
      return response;
    } else {
      return {
        success: false,
        error: response.error
      };
    }
  }

  async uploadPhotos(files, config) {
    let response = await this.api.places.uploadPhotos(files, config);
    if (response.success) {
      return response;
    } else {
      return {
        success: false,
        error: response.error
      };
    }
  }

  async updatePhotos(data) {
    let response = await this.api.places.updatePhotos(data);
    if (response.success) {
      return response;
    } else {
      return {
        success: false,
        error: response.error
      };
    }
  }

  async getPlace(id, date) {
    let response = await this.api.places.getGooglePlace(id, date);

    if (response.success) {
      return response;
    } else {
      return {
        success: false,
        error: response.error
      };
    }
  }

  async isFavorite(id) {
    let response = await this.api.places.isFavorite(id);

    if (response.success) {
      return response;
    } else {
      return {
        success: false,
        error: response.error
      };
    }
  }

  async getPhoto(photo_reference) {
    let response = await this.api.places.getPhoto(photo_reference);

    if (response.success) {
      return response;
    } else {
      return {
        success: false,
        error: response.error
      };
    }
  }

  async checkPlace(placeId) {
    let response = await this.api.places.checkPlace(placeId);

    //save token if success
    if (response.success) {
      return response;
    } else {
      return {
        success: false,
        error: response.error
      };
    }
  }
}
