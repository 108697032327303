import React from 'react';
import ReactDOM from 'react-dom';
import {BaseComponent} from '../../base-component';
import './style.css';

export class OpeningHours extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      opening_hours: null,
      opening_today: null,
      closing_today: null,
      mounted: false
    };

    this.getHours = this.getHours.bind(this);
  }

  componentDidMount() {
    this.getHours(this.props);
  }

  componentWillReceiveProps(newProps) {
    this.getHours(newProps);
  }

  getTime(date) {
    let hours_str = date.getHours().toString();
    let min_str = date.getMinutes().toString();

    if (hours_str.length < 2) {
      hours_str = "0" + hours_str;
    }

    if (min_str.length < 2) {
      min_str = "0" + min_str;
    }

    return parseInt(hours_str + min_str, 0);
  }

  getHours(newProps) {
    if (newProps.hours) {
      let opening_hours = JSON.parse(newProps.hours);
      console.log("opening_hours", opening_hours);
      let today = new Date();
      let dayNumber = today.getDay()

      console.log("dayNumber", dayNumber);

      if (opening_hours.periods.length >= dayNumber + 1) {

        let times = this.openNow(dayNumber, today, opening_hours);

        console.log("times", times);

        opening_hours.open_now = times.open_now;

        this.setState({
          open_now: times.open_now,
          opening_today: this.formatTime(times.open_time),
          closing_today: this.formatTime(times.close_time),
          mounted: true
        });
      }
    } else if (newProps.open_time) {
      this.setState({
        open_now: newProps.open_now,
        opening_today: this.formatTime(newProps.open_time),
        closing_today: this.formatTime(newProps.close_time),
        mounted: true
      });
    }
  }

  openNow(dayNumber, today, opening_hours) {
    let time = this.getTime(today);

    let open_now = false;
    let open_time = 0;
    let close_time = 0;

    console.log(time);

    for (let i = 0; i < opening_hours.periods.length; i++) {
      if (opening_hours.periods[i].close.day || opening_hours.periods[i].open.day === dayNumber) {

        if (opening_hours.periods[i].open.day === opening_hours.periods[i].close.day) {
          if (time >= parseInt(opening_hours.periods[i].open.time, 0) && time <= parseInt(opening_hours.periods[i].close.time, 0)) {
            open_now = true;
            break;
          }

          open_time = parseInt(opening_hours.periods[i].open.time, 0);
          close_time = parseInt(opening_hours.periods[i].close.time, 0);
        } else {
          if (opening_hours.periods[i].open.day === dayNumber) {
            open_now = time >= parseInt(opening_hours.periods[i].open.time, 0);
            open_time = parseInt(opening_hours.periods[i].open.time, 0);
            close_time = parseInt(opening_hours.periods[i].close.time, 0);
            break;
          }
        }
      }
    }

    return {open_now, close_time, open_time};
  }

  formatTime(time) {
    let timeInt = parseInt(time, 0) / 100;

    let timeOf = "am";

    if (timeInt > 11.59) {
      timeOf = "pm";
    }

    if (timeInt > 11.59) {
      timeInt = timeInt - 12;
    }

    if (timeInt === 0)
      timeInt = 12;

    let time_str = timeInt.toString().split(".");

    if (time_str.length > 1) {
      if (time_str[1].length > 2) {
        time_str[1] = time_str[1][0] + time_str[1][1];
      }
      if (time_str[1].length < 2) {
        time_str[1] += "0";
      }
      time_str = time_str[0] + ":" + time_str[1];
    }

    return time_str + timeOf;
  }


  render() {
    if (!this.state.mounted) {
      return null;
    }

    return (
      <div className="opening_hours">
        <span className="place_details_clock_now">{this.state.open_now ? "Open Now " : "Closed "}</span>
        <span className="place_details_clock_hours">{this.state.open_now ? " Closes at " : " Opens at "} {this.state.open_now ? this.state.closing_today : this.state.opening_today}</span>
      </div>
    );
  }
}
