import { BaseApiService } from './base';

export class UtilitiesService extends BaseApiService {
    inviteFriend(email) {
        return this.doPost('/api/invite', {email});
    }
    verifyToken(verifyToken) {
        return this.doPost('/api/verify', {verifyToken});
    }
    register(data) {
        return this.doPost('/api/register', data);
    }
    login(data) {
        return this.doGet('/api/login', data);
    }
    checkToken() {
        if(localStorage.getItem("token"))
            return true;
        return false;
    }
    async verifyLoginToken() {
        const res = await this.doGet('/api/check', {token: localStorage.getItem("token")});

        if(res.success) {
            localStorage.setItem("userId", res.data.userId)
        } 

        return res;
    }
    forgot(data) {
        return this.doPost('/api/forgot', data);
    }
    contactUs(data) {
        return this.doPost('/api/contact', data);
    }
}