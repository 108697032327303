import React, { Component } from "react";
import {
  Redirect,
  BrowserRouter,
  Route,
  Switch,
  Router
} from "react-router-dom";
import Home from "./app/pages/Home";
import Verify from "./app/pages/Verify";
import Header from "./app/containers/Header";
import Footer from "./app/containers/Footer";
import Profile from "./app/pages/Profile";
import Register from "./app/pages/Register";
import SignIn from "./app/pages/SignIn";
import ChangePassword from "./app/pages/ChangePassword";
import ForgotPassword from "./app/pages/ForgotPassword";
import ContactUs from "./app/pages/ContactUs";
import Feedback from "./app/pages/Feedback";
import Search from "./app/pages/Search";
import Privacy from "./app/pages/Privacy";
import Terms from "./app/pages/Terms";
import "./App.css";
import { Api } from "./app/services";
import AddPlace from "./app/pages/AddPlace";
import Place from "./app/pages/Place";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStroopwafel } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faWindowMaximize } from "@fortawesome/free-solid-svg-icons";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faUndo } from "@fortawesome/free-solid-svg-icons";
import { get } from "lodash";

library.add(faStroopwafel);
library.add(faStar);
library.add(faCheck);
library.add(faTimes);
library.add(faHeart);
library.add(faShareAlt);
library.add(faClock);
library.add(faMapMarkerAlt);
library.add(faPhone);
library.add(faWindowMaximize);
library.add(faQuoteLeft);
library.add(faQuoteRight);
library.add(faCopy);
library.add(faBars);
library.add(faUndo);

class App extends Component {
  state = {
    isLoggedIn: false,
    mounted: false,
    flash_invite: false
  };

  async componentDidMount(newProps) {
    if (process.env.NODE_ENV === "development") {
      await window.mixpanel.disable();
    }

    const res = await Api.utilities.verifyLoginToken();

    res.success && window.mixpanel.identify(get(res, "data.userId"));

    this.setState({
      isLoggedIn: res.success,
      mounted: true
    });
  }

  async componentWillReceiveProps(newProps) {
    let res = await Api.utilities.verifyLoginToken();

    this.setState({
      isLoggedIn: res.success,
      mounted: true
    });
  }

  checkToken = async next => {
    let res = await Api.utilities.verifyLoginToken();

    this.setState(
      {
        isLoggedIn: res.success,
        mounted: true
      },
      () => {
        next();
      }
    );
  };

  setFlashInvite = () => this.setState({ flash_invite: true });

  render() {
    return this.state.mounted ? (
      <BrowserRouter>
        <div className="main_content">
          <Header flashInvite={this.setFlashInvite} />
          <div className="content">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route
                exact
                path="/verify/:token"
                render={props => (
                  <Verify {...props} onVerify={this.checkToken} />
                )}
              />
              <Route exact path="/register" component={Register} />
              <Route
                exact
                path="/signin/:path?"
                render={props => (
                  <SignIn {...props} onSignIn={this.checkToken} />
                )}
              />
              <Route exact path="/forgot" component={ForgotPassword} />
              <Route exact path="/contact" component={ContactUs} />
              <Route exact path="/feedback" component={Feedback} />
              <Route exact path="/search" component={Search} />
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/terms" component={Terms} />
              <Route
                exact
                path="/place/:id"
                render={props => (
                  <Place
                    {...props}
                    onSignIn={this.checkToken}
                    checkToken={this.checkToken}
                    isLoggedIn={this.state.isLoggedIn}
                  />
                )}
              />

              {this.state.isLoggedIn ? (
                <div>
                  <Route
                    exact
                    path="/profile"
                    render={props => (
                      <Profile {...props} onSignOut={this.checkToken} />
                    )}
                  />
                  <Route
                    exact
                    path="/addplace/:id?"
                    render={props => (
                      <AddPlace
                        {...props}
                        checkToken={this.checkToken}
                        isLoggedIn={this.state.isLoggedIn}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/cp"
                    render={props => (
                      <ChangePassword {...props} onSignOut={this.checkToken} />
                    )}
                  />
                </div>
              ) : (
                <div>
                  <Route
                    exact
                    path="/profile"
                    render={() => <Redirect to="/signin/profile" />}
                  />
                  <Route
                    exact
                    path="/addplace"
                    render={() => <Redirect to="/signin/addplace" />}
                  />
                  <Route
                    exact
                    path="/cp"
                    render={() => <Redirect to="/signin/cp" />}
                  />
                </div>
              )}
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </div>
          <Footer flashInvite={this.state.flash_invite} />
        </div>
      </BrowserRouter>
    ) : (
      ""
    );
  }
}

export default App;
