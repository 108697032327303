import React from "react";
import "./style.css";

export default class SignIn extends React.Component {
    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown = e => {
        if (e.keyCode === 13) {
            this.handleSignInClick()
        }
    }

    handleSignInClick = e => {
        e && e.preventDefault();

        const email = this.email.value;
        const password = this.password.value;

        this.props.onSignInClick(email, password);
    }

    render() {
        const {message, errors} = this.props;

        return (
            <div>
                <input
                    className={errors.e ? "input_error" : "input_normal"}
                    ref={email => (this.email = email)}
                    type="email"
                    placeholder="email"
                    />
                <input
                    className={errors.p ? "input_error" : "input_normal"}
                    ref={password => (this.password = password)}
                    type="password"
                    placeholder="password"
                    />
                {message ? (
                    <div className="signin_message">{message}</div>
                    ) : (
                    ""
                    )
                }
                <button onClick={this.handleSignInClick}>Sign In</button>
                <div className="signin_callout">
                    <a
                        className="on_teal_link"
                        href="/forgot"
                        onClick={() =>
                            window.mixpanel.track("Forgot Passoword Clicked", {
                                source: "SignIn"
                            })}
                    >
                        Forgot Password?
                    </a>
                </div>
                <div className="signin_hr">
                    <span>Or</span>
                </div>
                <div>
                    <button
                        className="signin_register"
                        onClick={this.props.onRegisterClick}
                    >
                        Register
                    </button>
                </div>
            </div>
        )   
    }
}