
export default class Utilities {
    
    static validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    static getScore(features) {
        let score = 0;
        for(var i = 0; i < features.length; i++) {
          if(typeof(features[i]) === "string" ) {
            score += parseInt(JSON.parse(features[i]).points, 0);
          } else {
            score += features[i].points;
          }
        }
        return score;
      }
}
