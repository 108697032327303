import { BaseApiService } from './base';

export class PlacesService extends BaseApiService {
    search(data) {
        this.setToken(localStorage.getItem("token"));
        let userId = localStorage.getItem("userId");

        if(userId !== 'undefined') {
            data.userId = userId;
        }
        return this.doGet('/api/search', data);
    }

    search_all() {
        this.setToken(localStorage.getItem("token"));
        return this.doGet('/api/search_all');
    }

    getFeatures(email) {
        this.setToken(localStorage.getItem("token"));
        return this.doGet('/api/features', {email});
    }

    getCuisines() {
        this.setToken(localStorage.getItem("token"));
        return this.doGet('/api/cuisines');
    }

    addPlace(data) {
        this.setToken(localStorage.getItem("token"));
        return this.doPost('/api/auth/place', data);
    }

    uploadPhotos(files, config) {
        this.setToken(localStorage.getItem("token"));
        return this.doPostFormData('/api/auth/place/photos', files, config);
    }

    updatePhotos(data) {
        this.setToken(localStorage.getItem("token"));
        return this.doPost('/api/auth/place/photos_update', data);
    }

    getGoogleSearch(text) {
        let key = "AIzaSyAyeO_5PVV0JCkKN8phWxlzrpRM2TNwCF0"
        let inputType = "textquery";
        let url = `https://maps.googleapis.com/maps/api/place/findplacefromtext/json?key=${key}&input=${text}&inputtype=${inputType}`
        return this.doOutsideGet(url);
    }

    getGooglePlace(id, date) {
        this.setToken(localStorage.getItem("token"));
        return this.doGet('/api/place/' + id + '/' + date);
    }

    isFavorite(id) {
        this.setToken(localStorage.getItem("token"));
        return this.doGet('/api/auth/favorite/' + id);
    }

    getPhoto(photo_reference) {
        return this.doGet('/api/photo/' + photo_reference);
    }

    checkPlace(placeId) {
        this.setToken(localStorage.getItem("token"));
        return this.doGet('/api/auth/place/check/' + placeId);
    }

    addFavorite(data) {
        this.setToken(localStorage.getItem("token"));
        return this.doPost('/api/auth/favorites/add', data);
    }

    getFavorites(date) {
        this.setToken(localStorage.getItem("token"));
        return this.doGet('/api/auth/favorites/'+date, {});
    }

    addReview(data) {
        this.setToken(localStorage.getItem("token"));
        return this.doPost('/api/auth/reviews/add', data);
    }

    getReviews_Place(placeId) {
        console.log("placeId", placeId)
        this.setToken(localStorage.getItem("token"));
        return this.doGet('/api/reviews/place/' + placeId);
    }

    getReviews_User(page) {
        this.setToken(localStorage.getItem("token"));
        return this.doGet('/api/auth/reviews/user/' + page);
    }

    getAddedPlaces(date, page) {
        this.setToken(localStorage.getItem("token"));
        return this.doGet('/api/auth/places/user/' + date + '/' + page);
    }

    getUser() {
        this.setToken(localStorage.getItem("token"));
        return this.doGet('/api/auth/user');
    }

    changePassword(password) {
        this.setToken(localStorage.getItem("token"));
        return this.doPost('/api/auth/user/cp', {password});
    }

    saveProfile(data) {
        this.setToken(localStorage.getItem("token"));
        return this.doPost('/api/auth/user', data);
    }
}