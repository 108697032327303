import React from "react";
import Autosuggest from "react-autosuggest";
import { Loader } from "semantic-ui-react";
import "semantic-ui-css/components/loader.min.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "./style.css";

import axios from "../../assets/partners/axois_logo.jpg";
import high from "../../assets/partners/high_fives_logo.png";
import mount from "../../assets/partners/mount_sinai.png";
import senior from "../../assets/partners/senior_planet.jpg";
import spaulding from "../../assets/partners/spaulding_logo.jpg";
import spinal from "../../assets/partners/spinal.jpg";
import veterans from "../../assets/partners/veterans.jpg";
import { Features } from "../../components/Features";
import marker_img from "../../assets/marker_map.png";
import { PlacesController } from "../../controllers/placesController";
import { PlaceTile } from "../../components/PlaceTile";
import { cloneDeep } from "lodash";
import ModalComponent from "../../components/Modal";

import BostonPlaces from "./BostonPlaces.json";
import BostonTen from "./BostonTen.json";
import NYCPlaces from "./NYCPlaces.json";
import NYCTen from "./NYCTen.json";

const CITY_BOUNDS = {
  "Boston": {
    nelat: 42.717664151775345,
    nelng: -70.49439245035762,
    swlat: 42.160253717666194,
    swlng: -71.38703161051387,
  },
  "New York": {
    nelat: 41.85768593349366,
    nelng: -71.13955529525282,
    swlat: 39.5679997649231,
    swlng: -74.71011193587782,
  },
};

const MAP_CENTERS = {
  "Boston": {lat: 42.3601, lng: -71.0589},
  "New York":  {lat: 40.745368, lng: -73.977701}
}

// TODO: Refactor
export default class Home extends React.Component {
  map = null;
  bounds = {};

  constructor(props) {
    super(props);

    this.resultsRef = React.createRef();

    this.state = {
      filterModalOpen: false,
      openSignIn: props.location.hash === "#signin",
      openRegister: props.location.hash === "#register",
      isLoading: false,
      searchResults: [],
      searchText: "",
      selectedCity: localStorage.getItem("city") || 'New York',
      lastSearchText: "",
      suggestions: [],
      mounted: true,
      markers: [],
      filterOpen: false,
      features: [],
      filter: {
        gRating: 0,
        gPrice: [1, 0, 0, 0, 0],
        gOpen: 0,
        tMarker: 0
      },
      openFilter: {
        date: {
          time: new Date().toLocaleTimeString(),
          day: new Date().getDay()
        },
        filter: false,
        yes: false
      },
      isMobile: window.innerWidth <= 768,
      map_class: "in_map",
      page_name: "",
      page_num: 0,
      page_sum: 0,
      mobile_map: window.innerWidth <= 768 ? true : false,
      cuisines: [],
      initial: {
        initial: true,
        position: MAP_CENTERS['New York'],
        first_ten: []
      }
    };

    this.partners = [
      { image: axios, url: "http://axisproject.org/" },
      { image: high, url: "https://highfivesfoundation.org/" },
      {
        image: mount,
        url:
          "https://www.mountsinai.org/care/rehab-medicine/services/spinal-cord-injury/programs"
      },
      { image: senior, url: "https://seniorplanet.org/" },
      { image: spaulding, url: "https://spauldingrehab.org/" },
      { image: spinal, url: "https://www.nycspinalcord.org/" },
      { image: veterans, url: "http://www.nepva.org/about_nepva.html" }
    ];

    this.controller = new PlacesController();

    this.drawPartnerImages = this.drawPartnerImages.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.selectGFilter = this.selectGFilter.bind(this);
    this.selectGPrice = this.selectGPrice.bind(this);
    this.selectGOpen = this.selectGOpen.bind(this);
    this.toggleFeature = this.toggleFeature.bind(this);
    this.trackScrolling = this.trackScrolling.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.changePage = this.changePage.bind(this);
    this.enterPressed = this.enterPressed.bind(this);
    this.toggleMap = this.toggleMap.bind(this);
    this.drawCuisines = this.drawCuisines.bind(this);
    this.openWindow = this.openWindow.bind(this);
  }

  async componentDidMount() {
    this.fillMapWithCachedData(this.state.selectedCity)

    const { features } = await this.controller.getFeatures();
    this.setState({features});

    const userData = await this.controller.getUser();
    let selectedCity = "New York";
    if (userData && userData.user && userData.user.city !== this.state.selectedCity) {
      const {city} = userData.user;
      this.setState({selectedCity: city});
      localStorage.setItem('city', city);
      this.fillMapWithCachedData(city);
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.location.hash !== this.props.location.hash) {
      this.setState({
        openSignIn: newProps.location.hash === "#signin",
        openRegister: newProps.location.hash === "#register"
      });
    }
  }

  drawPartnerImages(image, index) {
    return (
      <div className="partner_image" key={index + "partner"}>
        <span className="helper"></span>
        <a target="_blank" href={this.partners[index].url}>
          <img
            src={this.partners[index].image}
            width="150"
            alt="test"
            onDragStart={this.handleOnDragStart}
            className="yours-custom-class"
          />
        </a>
      </div>
    );
  }

  toggleFeature(event, index) {
    const features = this.state.features.slice(0);
    features[index].checked = !features[index].checked;

    this.setState({ features });
  }

  getFeatures(features) {
    let newFeatures = [];
    for (var i = 0; i < features.length; i++) {
      if (features[i].checked) {
        newFeatures.push(features[i].id);
      }
    }
    return JSON.stringify({ features: newFeatures });
  }

  handleKeyDown = e => {
    const { searchText, lastSearchText } = this.state;

    if (e.keyCode === 8 && searchText === lastSearchText) {
      this.setState({ searchText: "" });
    }
  };

  handleSearchButtonClick = e => {
    this.setState({ bounds: null }, async () => {
      const { searchText, features, filter, openFilter, selectedCity } = this.state;

      const formattedFeatures = this.getFeatures(features);
      const formattedSearchFilter = JSON.stringify(filter);
      const formattedOpenFilter = JSON.stringify(openFilter);

      window.mixpanel.track("Search Button Clicked", {
        selectedCity,
        searchText,
        formattedFeatures,
        formattedSearchFilter,
        formattedOpenFilter,
        bounds: null,
        source: "Home"
      });

      await this.search();

      this.scrollToResults();
    });
  };

  handleSearchThisAreaButtonClick = e => {
    this.setState(
      { searchAreaOpen: false, bounds: this.bounds, searchText: "" },
      () => {
        const { searchText, features, filter, openFilter } = this.state;

        const formattedFeatures = this.getFeatures(features);
        const formattedSearchFilter = JSON.stringify(filter);
        const formattedOpenFilter = JSON.stringify(openFilter);

        window.mixpanel.track("Search This Area Button Clicked", {
          searchText,
          formattedFeatures,
          formattedSearchFilter,
          formattedOpenFilter,
          bounds: this.bounds,
          source: "Home"
        });

        this.search();
      }
    );
  };

  fillMapWithCachedData = city => {
    let cachedPlaces, position, first_ten;
    switch(city) {
      case "New York":
        cachedPlaces = NYCPlaces;
        first_ten = NYCTen;
        position = MAP_CENTERS[city];
        break;
      case "Boston":
        cachedPlaces = BostonPlaces;
        first_ten = BostonTen;
        position = MAP_CENTERS[city];
        break;
      default:
        cachedPlaces = [...NYCPlaces, ...BostonPlaces];
        first_ten = NYCTen;
        position = MAP_CENTERS["New York"];
    }

     this.cached_places = {
      success: true,
      count: cachedPlaces.length,
      results: cachedPlaces
    };

    this.setState({
      initial: {
        initial: true,
        position,
        first_ten,
      }
    }, () => this.fillMap(this.cached_places, true));
  }

  search = setBounds => {
    const { searchText, selectedCity } = this.state;

    this.setState(
      {
        initial: { initial: false },
        isLoading: true,
        searchResults: [],
        filterModalOpen: false,
        lastSearchText: searchText
      },
      async () => {
        const features = this.getFeatures(this.state.features);
        // let sortValue = this.sortValue.value;

        const searchFilter = JSON.stringify(this.state.filter);
        const openFilter = JSON.stringify(this.state.openFilter);

        /* let cuisineFilter = this.selectedCuisine.value; */

        /* console.log("this.inaccessible", this.inaccessible.checked);

        let inaccessible = this.inaccessible.checked ? "1" : "0";

        console.log("inaccessible", inaccessible); */

        const bounds = this.state.bounds ? this.state.bounds : selectedCity ? CITY_BOUNDS[selectedCity] : null;

        const searchResults = await this.controller.search(
          searchText.trim(),
          1,
          searchFilter,
          features,
          openFilter,
          this.state.isPage ? this.state.page_num : 0,
          null,
          null,
          bounds
        );

        this.fillMap(searchResults, setBounds);

        /* this.setState({bounds: null}); */
      }
    );
  };

  // TODO: Merge this and updateMap
  fillMap = (searchResults, setBounds) => {
    if (!this.map) {
      this.initMap();
    }

    if (searchResults.success) {
      this.setState(
        {
          isLoading: false,
          searchResults: searchResults.results,
          page_sum: this.getPageNum(searchResults.count),
          isPage: false,
          page_num: this.state.isPage ? this.state.page_num : 0
        },
        () => {
          this.updateMap(setBounds);
          /* if(searchResults.results.length > 0)
          this.scrollToSearch(); */
        }
      );
    } else {
      this.setState(
        {
          isLoading: false,
          searchResults: [],
          page_sum: 0
        },
        () => this.updateMap(setBounds)
      );
    }
  };

  getPageNum(count) {
    let divide = parseInt(count / 10, 0);

    if (divide * 10 < count) divide++;

    return divide;
  }

  initMap = () => {
    this.map = new window["google"].maps.Map(document.getElementById("map"), {
      center: this.state.initial.position,
      zoom: 12,
      mapTypeId: "roadmap",
      disableDefaultUI: this.state.isMobile,
    });


    this.map.addListener("dragend", () => {
      var bounds = this.map.getBounds();
      var ne = bounds.getNorthEast();
      var sw = bounds.getSouthWest();

      let nelng = ne.lng();
      let nelat = ne.lat();

      let swlng = sw.lng();
      let swlat = sw.lat();

      this.bounds = {
        nelng,
        nelat,
        swlng,
        swlat
      };

      this.setState({ searchAreaOpen: true });
    });
  };

  updateMap = (setBounds = true) => {
    const { searchResults, features } = this.state;

    if (!this.map) {
      // this.initMap();
    }

    // Remove all
    this.state.markers.forEach(marker => {
      marker.setMap(null);
      new window.google.maps.event.clearListeners(marker, "click");
    });

    if (searchResults.length > 0) {
      const results = searchResults;
      const markers = [];

      // Center map around first result initially
      let position = null;
      if (!this.state.searchAreaOpen) {
        position = {
          lat: parseFloat(results[0].lat),
          lng: parseFloat(results[0].lng)
        };
      }

      const infoWindow = new window["google"].maps.InfoWindow({});
      const bounds = new window["google"].maps.LatLngBounds();

      for (let i = 0; i < results.length; i++) {
        const place = results[i];

        position = {
          lat: parseFloat(place.lat),
          lng: parseFloat(place.lng)
        };

        /* let score = place.feature_sum; */
        const { name: placeName, features: placeFeatures } = place;

        const feautureImages = features.map(feature =>
          placeFeatures.indexOf(feature.id) !== -1
            ? feature.icon
            : feature.iconNot
        );

        // <div>${place.address ? place.address : ""}</div>

        const html = `
          <div class="map_marker">
            <h2>
              <a target="_blank" href="/place/${
                place.id
              }" onclick="window.mixpanel.track('Marker Place Clicked', {place_name: '${placeName}', source: 'Home'})">
                ${placeName}
              </a>
            </h2>
            <div class="feature_images">
              <img class="feature_image" src=${feautureImages[0]} />
              <img class="feature_image" src=${feautureImages[1]} />
              <img class="feature_image" src=${feautureImages[2]} />
              <img class="feature_image" src=${feautureImages[3]} />
            </div>
          </div>
          `;

        const marker = new window.google.maps.Marker({
          map: this.map,
          position,
          title: placeName,
          html,
          //icon: score < 14 ? marker_bad_img : (score > 18 ? marker_img : marker_ok_img)
          icon: marker_img
        });

        marker.addListener("click", () => {
          infoWindow.setContent(html);
          infoWindow.open(this.map, marker);

          window.mixpanel.track("Marker Clicked", {
            place_name: placeName,
            source: "Home"
          });

          this.scrollToTile(i);
        });

        markers.push(marker);
        bounds.extend(marker.getPosition());
      }

      // Fit all to bounds
      // initial.initial???
      // TODO: Refactor
      if (setBounds && results.length) {
        this.map.fitBounds(bounds);
        if (results.length === 1) {
          this.map.setZoom(17);
        }
      }

      this.setState({ markers });
    }
  };

  scrollToTile = index => {
    const position = document.getElementById("place_tile_" + index);

    if (position) {
      document.getElementById("results_container").scrollTop =
        position.offsetTop - 40;
    }
  };

  renderSearchResult = (place, index) => {
    return (
      <PlaceTile
        index={index}
        place={place}
        features={this.state.features}
        source="Home"
      />
    );
  };

  handleFilterButtonClick = () => {
    this.setState({ bounds: null }, async () => {
      const { searchText, features, filter, openFilter } = this.state;

      const formattedFeatures = this.getFeatures(features);
      const formattedSearchFilter = JSON.stringify(filter);
      const formattedOpenFilter = JSON.stringify(openFilter);

      window.mixpanel.track("Apply Filter Button Clicked", {
        searchText,
        formattedFeatures,
        formattedSearchFilter,
        formattedOpenFilter,
        bounds: null,
        source: "Home"
      });

      await this.search();

      this.scrollToResults();
    });
  };

  toggleFilter() {
    this.setState({
      filterOpen: !this.state.filterOpen,
      page_num: 0
    });
  }

  searchFilter() {
    this.setState({
      filterOpen: !this.state.filterOpen,
      page_num: 0
    });
  }

  resetFilter() {
    let features = this.state.features;

    for (var i = 0; i < features.length; i++) {
      features[i].checked = false;
    }

    this.setState(
      {
        filterOpen: !this.state.filterOpen,
        features,
        openFilter: {
          date: {
            time: new Date().toLocaleTimeString(),
            day: new Date().getDay()
          },
          filter: false,
          yes: false
        },
        filter: {
          gRating: 0,
          gPrice: 0,
          gOpen: 0,
          tMarker: 0
        }
      },
      () => {
        if (!this.state.filterOpen) {
          this.search();
        }
      }
    );
  }

  selectGFilter(gRating) {
    this.setState({
      filter: {
        ...this.state.filter,
        gRating
      }
    });
  }

  selectGPrice(index) {
    let gPrice = cloneDeep(this.state.filter.gPrice);
    gPrice[index] = this.state.filter.gPrice[index] === 0 ? 1 : 0;

    this.setState({
      filter: {
        ...this.state.filter,
        gPrice
      }
    });
  }

  selectGOpen(gOpen) {
    this.setState({
      filter: {
        ...this.state.filter,
        gOpen
      },
      openFilter: {
        date: {
          time: new Date().toLocaleTimeString(),
          day: new Date().getDay()
        },
        filter: gOpen !== 0,
        yes: gOpen === 1
      }
    });
  }

  selectTMarker(tMarker) {
    this.setState({
      filter: {
        ...this.state.filter,
        tMarker
      }
    });
  }

  trackScrolling() {
    let scroll = this.checkMapScroll();
    let map_class = "in_map";

    switch (scroll) {
      case 0:
        map_class = "in_map";
        break;
      case 1:
        map_class = "fixed_map";
        break;
      case 2:
        map_class = "absolute_map";
        break;
      default:
        break;
    }

    this.setState({
      map_class
    });
  }

  // TODO: Delete this
  scrollToSearch = () => window.scrollTo(0, 220);

  scrollToResults = () =>
    window.scrollTo(0, this.resultsRef.current.offsetTop - 20);

  checkMapScroll() {
    let scroll_num = 0;
    let search_comp = document.getElementById("sort_element").bottom;

    let footer = document
      .getElementById("footer_container")
      .getBoundingClientRect().bottom;

    scroll_num =
      footer < window.pageYOffset + window.innerHeight
        ? 2
        : search_comp < 1
        ? 1
        : 0;

    return scroll_num;
  }

  changePage(offset) {
    this.setState(
      {
        page_num: this.state.page_num + offset,
        isPage: true,
        initial: {
          set: false
        }
      },
      () => {
        this.search();
      }
    );
  }

  enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.search();
    }
  }

  toggleMap() {
    if (this.state.mobile_map) {
      // Switching to List view
      window.mixpanel.track("Switched to List View", { source: "Home" });
      window.mixpanel.time_event("Switched to Map View", { source: "Home" });
    } else {
      // Switching to Map view
      window.mixpanel.track("Switched to Map View", { source: "Home" });
      window.mixpanel.time_event("Switched to List View", { source: "Home" });
    }

    this.setState({
      mobile_map: !this.state.mobile_map
    });
  }

  drawCuisines(cuisine, index) {
    return <option value={cuisine.id}>{cuisine.name}</option>;
  }

  renderFilter = () => {
    return (
      <div className="filter_modal">
        <div className="search_filter_drop">
          <div className="search_filter_bottom">
            <div className="features_title_section">
              <h3>Accessibility Features</h3>
              <p>
                Please check which features you need to get around comfortably:
              </p>
            </div>
            <Features
              showCheckBox={true}
              stairs={false}
              features={this.state.features}
              toggleFeature={this.toggleFeature}
            />
          </div>

          <div className="search_filter_top">
            <div className="search_filter_left">
              <h3>Other Features</h3>
              {/* <div>
              <div className="google_price_filter filter_buttons">
                  <div className="name">Cusine Type: </div>
                  <select ref={node=> this.selectedCuisine = node} onChange={this.search}>
                      <option value={-1}>Any</option>
                  </select>
              </div>
            </div> */}
              <div>
                <div className="google_price_filter filter_buttons">
                  <div className="name">Price: </div>
                  <div className="the_buttons">
                    <div
                      className={
                        "filter_button" +
                        (this.state.filter.gPrice[0] === 1 ? " selected" : "")
                      }
                      onClick={() => this.selectGPrice(0)}
                    >
                      Any
                    </div>
                    <div
                      className={
                        "filter_button" +
                        (this.state.filter.gPrice[1] === 1 ? " selected" : "")
                      }
                      onClick={() => this.selectGPrice(1)}
                    >
                      $
                    </div>
                    <div
                      className={
                        "filter_button" +
                        (this.state.filter.gPrice[2] === 1 ? " selected" : "")
                      }
                      onClick={() => this.selectGPrice(2)}
                    >
                      $$
                    </div>
                    <div
                      className={
                        "filter_button" +
                        (this.state.filter.gPrice[3] === 1 ? " selected" : "")
                      }
                      onClick={() => this.selectGPrice(3)}
                    >
                      $$$
                    </div>
                    <div
                      className={
                        "filter_button" +
                        (this.state.filter.gPrice[4] === 1 ? " selected" : "")
                      }
                      onClick={() => this.selectGPrice(4)}
                    >
                      $$$$
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="google_open_filter filter_buttons">
                  <div className="name">Open Now: </div>
                  <div className="the_buttons">
                    <div
                      className={
                        "filter_button" +
                        (this.state.filter.gOpen === 0 ? " selected" : "")
                      }
                      onClick={() => this.selectGOpen(0)}
                    >
                      Any
                    </div>
                    <div
                      className={
                        "filter_button" +
                        (this.state.filter.gOpen === 1 ? " selected" : "")
                      }
                      onClick={() => this.selectGOpen(1)}
                    >
                      Yes
                    </div>
                    <div
                      className={
                        "filter_button" +
                        (this.state.filter.gOpen === 2 ? " selected" : "")
                      }
                      onClick={() => this.selectGOpen(2)}
                    >
                      No
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="google_rating_filter filter_buttons">
                  <div className="name">Food Rating: </div>
                  <div className="the_buttons">
                    <div
                      className={
                        "filter_button" +
                        (this.state.filter.gRating === 0 ? " selected" : "")
                      }
                      onClick={() => this.selectGFilter(0)}
                    >
                      Any
                    </div>
                    <div
                      className={
                        "filter_button" +
                        (this.state.filter.gRating === 3 ? " selected" : "")
                      }
                      onClick={() => this.selectGFilter(3)}
                    >
                      3+
                    </div>
                    <div
                      className={
                        "filter_button" +
                        (this.state.filter.gRating === 3.5 ? " selected" : "")
                      }
                      onClick={() => this.selectGFilter(3.5)}
                    >
                      3.5+
                    </div>
                    <div
                      className={
                        "filter_button" +
                        (this.state.filter.gRating === 4 ? " selected" : "")
                      }
                      onClick={() => this.selectGFilter(4)}
                    >
                      4+
                    </div>
                    <div
                      className={
                        "filter_button" +
                        (this.state.filter.gRating === 4.5 ? " selected" : "")
                      }
                      onClick={() => this.selectGFilter(4.5)}
                    >
                      4.5+
                    </div>
                  </div>
                </div>
              </div>
              {/* <div>
                Include Inaccessible Places: <input type="checkbox" ref={node => this.inaccessible = node} />
              </div> */}
            </div>
            <div className="search_filter_right">
              {/* <div>TravelEZ Rating:
                <div className="travelez_rating_filter filter_buttons">
                  <div className={"filter_button" + (this.state.filter.tMarker === 0 ? " selected" : "")} onClick={ ()=> this.selectTMarker(0)}>
                    Any
                  </div>
                  <div className={"filter_button" + (this.state.filter.tMarker === 1 ? " selected" : "")} onClick={ ()=> this.selectTMarker(1)}>
                    <img alt="travelez marker bad" src={marker_bad_img} />
                  </div>
                  <div className={"filter_button" + (this.state.filter.tMarker === 2 ? " selected" : "")}  onClick={ ()=> this.selectTMarker(2)}>
                    <img alt="travelez marker good" src={marker_ok_img} />
                  </div>
                  <div className={"filter_button" + (this.state.filter.tMarker === 3 ? " selected" : "")}  onClick={ ()=> this.selectTMarker(3)}>
                    <img alt="travelez marker great" src={marker_img} />
                  </div>
                </div>
                <div className="filter_buttons_wrapper">
                  <span className="button_close" onClick={this.toggleFilter}>X</span>
                  <div className="reset_button">
                    <button className="primary_button"  onClick={this.resetFilter} >reset</button>
                  </div>
                  <div className="search_button">
                    <button className="primary_button" onClick={this.searchFilter}>search</button>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="filter_button_wrapper">
          <button
            className="primary_button"
            onClick={this.handleFilterButtonClick}
          >
            Apply Filter
          </button>
          <div className="set_preferences">
            <a
              style={{ color: "white", fontSize: "14px", fontWeight: "bold" }}
              href="/profile"
              onClick={() =>
                window.mixpanel.track(
                  "Set your preferences for w/c access entry",
                  { source: "Home" }
                )
              }
            >
              Set your preferences for Wheelchair Accessible Entry
            </a>
          </div>
        </div>
      </div>
    );
  };

  renderPagination = () => {
    const { isMobile } = this.state;

    return (
      <div className="search_pager_wrapper">
        <div className="search_pager_content">
          {this.state.page_num !== 0 ? (
            <button
              onClick={() => this.changePage(-1)}
              className="pagination_button"
            >
              &lt; {!isMobile && "Prev"}
            </button>
          ) : (
            // This is to take the space of the pagination_button
            // Needed to evenly space elements (due to justify-content space-between)
            <div style={{ width: 80 }} />
          )}
          <div className="page_num">
            Page <strong>{this.state.page_num + 1}</strong> of{" "}
            {this.state.page_sum}
          </div>
          {this.state.page_num + 1 < this.state.page_sum ? (
            <button
              className="pagination_button"
              onClick={() => this.changePage(1)}
            >
              {!isMobile && "Next"} &gt;
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  openWindow() {
    this.setState({
      filterModalOpen: !this.state.filterModalOpen
    });
  }

  handleAddPlacePress = e => {
    window.mixpanel.track("Add A Place Clicked", {
      source: "Home"
    });

    this.props.history.push("/addplace");
  };

  handleOnDragStart = e => e.preventDefault();

  handleSearchTextChange = (event, { newValue }) => {
    this.setState({ searchText: newValue });
  };

  handleSuggestionsFetchRequested = async ({ value }) => {
    // fetch search results

    const searchFilter = JSON.stringify(this.state.filter);
    const openFilter = JSON.stringify(this.state.openFilter);
    const features = this.getFeatures(this.state.features);

    const bounds = this.state.selectedCity ? CITY_BOUNDS[this.state.selectedCity] : null;

    const searchResults = await this.controller.search(
      value.trim(),
      1,
      searchFilter,
      features,
      openFilter,
      this.state.isPage ? this.state.page_num : 0,
      null,
      null,
      bounds
    );

    const results =
      searchResults && searchResults.success && searchResults.results;

    if (results && results.length) {
      // Show only the first 5 (out of 10) results
      this.setState({ suggestions: results.slice(0, 5) });
    } else {
      this.setState({ suggestions: [] });
    }
  };

  handleSuggestionsClearRequested = () => this.setState({ suggestions: [] });

  handleSuggestionSelected = () => {
    this.setState({ bounds: null }, async () => {
      const { searchText, features, filter, openFilter } = this.state;

      const formattedFeatures = this.getFeatures(features);
      const formattedSearchFilter = JSON.stringify(filter);
      const formattedOpenFilter = JSON.stringify(openFilter);

      window.mixpanel.track("Suggestion Selected", {
        searchText,
        formattedFeatures,
        formattedSearchFilter,
        formattedOpenFilter,
        bounds: null,
        source: "Home"
      });

      await this.search(true);

      this.scrollToResults();
    });
  };

  handleSelectedCityChange = e => {
    const selectedCity = e.target.value;

    window.mixpanel.track("City Changed", {
      selectedCity,
      source: "Home",
    });

    this.setState({selectedCity});

    this.fillMapWithCachedData(selectedCity)
  }

  getSuggestionValue = suggestion => suggestion.name;

  renderSuggestion = suggestion => (
    <div className="autosuggest-item">{suggestion.name}</div>
  );

  renderSuggestionsContainer = ({ containerProps, children, query }) => {
    containerProps.className = containerProps.className + "autocomplete-items";
    return (
      <div {...containerProps}>
        {/* {query && query.length && (
          <div>
            Press Search to search <strong>{query}</strong>
          </div>
        )} */}
        {children}
      </div>
    );
  };

  render() {
    const { suggestions, searchText, filterModalOpen, isMobile, selectedCity } = this.state;

    const inputProps = {
      placeholder: "Enter place name",
      value: searchText,
      onChange: this.handleSearchTextChange
    };

    return (
      <div className="home_content">
        <div className="home_main">
          <div className="search_page">
            <div className="search_page_header">
              <div className="search_main">
                <div className="search_hint">Search in</div>
                <select
                  className="search_options"
                  onChange={this.handleSelectedCityChange}
                  value={selectedCity}
                >
                  <option value={null}>All</option>
                  <option value="New York">New York</option>
                  <option value="Boston">Boston</option>
                </select>
                <div className="search_hint">for</div>
                <div className="search_bar">
                  <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={
                      this.handleSuggestionsFetchRequested
                    }
                    onSuggestionsClearRequested={
                      this.handleSuggestionsClearRequested
                    }
                    onSuggestionSelected={this.handleSuggestionSelected}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    renderSuggestionsContainer={this.renderSuggestionsContainer}
                    inputProps={inputProps}
                  />
                  {/* <div
                      className="search_input"
                      ref={node => (this.search_results = node)}
                    >
                      <input
                        onKeyPress={this.enterPressed}
                        onKeyDown={this.handleKeyDown}
                        type="text"
                        value={this.state.searchText}
                        onChange={this.handleSearchTextChange}
                        placeholder="Search place"
                      />
                    </div> */}
                </div>
                <div className="search_button">
                  <button onClick={this.handleSearchButtonClick}>Search</button>
                </div>
                {/* Sort by  */}
                {/* <div id="sort_element" className="search_sort_filter">
                    <div className="search_sort">
                      <div>
                        <div>
                          <select
                            ref={node => (this.sortValue = node)}
                            onChange={this.search}
                          >
                            <option value="-1">Sort By</option>
                            <option value="1">Google User Rating</option>
                            <option value="2">Alphabetical: A to Z</option>
                            <option value="3">Alphabetical: Z to A</option>
                            <option value="4">Price: Low to High</option>
                            <option value="5">Price: Hight to Low</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div> */}
              </div>
              {isMobile
                ? filterModalOpen && (
                    <ModalComponent closeModal={this.openWindow}>
                      {this.renderFilter()}
                    </ModalComponent>
                  )
                : this.renderFilter()}
              <div className="mobile_icons">
                <div className="mobile_select">
                  <select
                    className="search_options"
                    onChange={this.handleSelectedCityChange}
                    value={selectedCity}
                  >
                    <option value={null}>All</option>
                    <option value="New York">New York</option>
                    <option value="Boston">Boston</option>
                  </select>
                </div>
                <div className="mobile_map_icon">
                  <span onClick={this.toggleMap}>
                    {this.state.mobile_map ? "List" : "Map"}
                  </span>
                </div>
                <div className="mobile_map_icon filter_icon">
                  <span onClick={this.openWindow}>Filter</span>
                </div>
              </div>
            </div>

            <div
              ref={this.resultsRef}
              className={
                "search_page_results " +
                (this.state.mobile_map ? "map_showing" : "")
              }
            >
              <div className={`results_hint ${this.state.mobile_map ? "map_showing" : ""}`}>Showing <strong>{this.state.searchResults.length}</strong> place(s)</div> 
              <div
                ref={node => (this.refResults = node)}
                id="search_page_left"
                className="search_page_left"
              >
                {this.state.searchResults.length > 0 ? (
                  this.renderPagination()
                ) : this.state.isLoading ? (
                  <Loader inverted active size="large">
                    Loading
                  </Loader>
                ) : (
                  <div
                    className="search_pager_wrapper"
                    style={{
                      textAlign: "center",
                      backgroundColor: "transparent"
                    }}
                  >
                    <div>
                      No results found. Add a place here:
                      <br />
                      <br />
                      <button
                        className="primary_button"
                        onClick={this.handleAddPlacePress}
                      >
                        Add a Place
                      </button>
                    </div>
                  </div>
                )}

                {this.state.initial.initial ? (
                  <div id="results_container">
                    {this.state.initial.first_ten.map(this.renderSearchResult)}
                  </div>
                ) : this.state.searchResults.length > 0 ? (
                  <div id="results_container">
                    {this.state.searchResults.map(this.renderSearchResult)}
                  </div>
                ) : (
                  ""
                )}
                {isMobile && this.renderPagination()}
              </div>
              <div
                className={
                  "search_page_right " +
                  (this.state.mobile_map ? "mobile_map" : "")
                }
              >
                <div
                  className="place_map_wrap "
                  style={{ position: "relative" }}
                >
                  {this.state.searchAreaOpen ? (
                    <button
                      style={{
                        position: "absolute",
                        top: "20px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        padding: 5,
                        left: 0,
                        right: 0,
                        zIndex: "10000"
                      }}
                      className="primary_button"
                      onClick={this.handleSearchThisAreaButtonClick}
                    >
                      search this area
                    </button>
                  ) : (
                    ""
                  )}

                  <div id="map" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="home_add_review">
                    <button onClick={this.goToAddPlace} >+ Add A Place</button>
                    <div className="review_description">
                        Click above to add your own accessibility review to the TravelEZ website.
                    </div>
                </div>
                <div className="home_how_it_works">
                    <h2>How TravelEZ Works</h2>
                    <div className="how_it_works_description">
                        TravelEZ crowdsources accessibility reviews of
                        places based on a unique set of criteria. Based on
                        these criteria, our algorithm rates a restaurant as:
                    </div>
                    <div className="how_it_works_scores">
                        <div className="how_it_works_panel">
                            <div>
                                <img alt="good_accessibility" src={marker} />
                                <h3>Awesome Accessibility</h3>
                                <div>
                                    Good for <b>anybody</b>, including wheelchair users, slow walkers,
                                    and people using crutches, canes or walkers.
                                </div>
                            </div>
                        </div>
                        <div className="how_it_works_panel">
                            <div>
                                <img alt="ok_accessibility" src={marker_ok} />
                                <h3>Medium Accessibility</h3>
                                <div>
                                    Probably not good enough for wheelchair users or <b>very</b> slow walkers, but good for slow walkers, people using walkers, crutches, or cane.
                                </div>
                            </div>
                        </div>
                        <div className="how_it_works_panel">
                            <div>
                                <img alt="bad_accessibility" src={marker_bad} />
                                <h3>Not Recommended</h3>
                                <div>
                                    We recommend avoiding these places altogether, as even
                                    the most athletic of us might face physical challenges at these establishments.
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
        {!filterModalOpen && <div className="mobile_map_icon add_place_icon" onClick={this.handleAddPlacePress}>
          {/* <span onClick={this.handleAddPlacePress}>Add A<br/>Place</span> */}
          <span>Add a<br />Place</span>
        </div>
        }
      </div>
    );
  }

  /* <div className="home_partners">
          <h2>TravelEZ is proud to partner with the following organizations:</h2>
          <div className="home_partners_images">
            <AliceCarousel
              responsive={{
                1024: {items: 5},
                414: {items: 2},
                319: {items: 2},
              }}
              buttonsDisabled={true}
              mouseDragEnabled >


              {this.partners.map((image, index) => this.drawPartnerImages(image, index))}
            </AliceCarousel>
          </div>
        </div> */
}
