import React from "react";
import "./style.css";
import * as logo from "../../assets/logo.png";
import { BaseComponent } from "../../base-component";

export default class Footer extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      error: false,
      invite_class: "refer_content"
    };

    this.inviteFriend = this.inviteFriend.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.flashInvite) {
      this.setState(
        {
          invite_class: "flash_invite"
        },
        () => {
          setTimeout(() => {
            this.setState({
              invite_class: "refer_content"
            });
          }, 2000);
        }
      );
    }
  }

  async inviteFriend() {
    window.mixpanel.track("Share Submitted", {
      email: this.email.value,
      source: "Footer"
    });

    this.setState({
      message: true
    });
    var x = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var reg = new RegExp();
    let log = this.email.value.match(x);

    if (log) {
      let result = await this.api.utilities.inviteFriend(this.email.value);
      if (result.success) {
        this.email.value = null;
        this.setState({
          message:
            "Thank you for referring a friend! Feel free to refer as many friends as you like.",
          error: false
        });
      } else {
        this.setState({
          message: result.message,
          error: true
        });
      }
    } else {
      this.setState({
        message: "Please check the email format.",
        error: true
      });
    }
  }

  render() {
    return (
      <div id="footer_container" className="main_footer">
        <div className="main_footer_split">
          <div className="main_footer_content">
            <div className="main_footer_column main_footer_links">
              <a href="/">Search</a>
              <a href="feedback">Leave Feedback</a>
              <a href="signin">Sign In / Register</a>
              <a href="/addplace">Add A Place</a>
            </div>
            <div className="main_footer_column main_footer_links">
              <a href="/contact">Contact Us</a>
              <a href="/terms">Terms & Conditions</a>
              <a href="/privacy">Privacy Policy</a>
            </div>
            <div className="main_footer_column ">
              <div className="main_footer_title">REFER A FRIEND</div>
              <div className="main_footer_help">
                Help friends, family, and fellow advocates find and rate
                accessible restaurants!
              </div>
              <div className="main_footer_refer">
                <input
                  ref={node => (this.email = node)}
                  className={this.state.invite_class}
                  placeholder="Friend's Email"
                  type="email"
                  required
                  title="Not a valid email address."
                />
                <button onClick={this.inviteFriend}>Submit</button>
              </div>
              {this.state.message ? (
                <div
                  className={
                    this.state.error ? "error_message" : "main_footer_thankYou"
                  }
                >
                  {this.state.message}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="main_footer_legal">
            &copy; Copyright TravelEZ {new Date().getFullYear()}
          </div>
        </div>
      </div>
    );
  }
}
