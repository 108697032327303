import React from "react";
// import Modal from "react-modal";
import { BaseComponent } from "../../base-component";
import "./style.css";

// TODO: Refactor
export default class ModalComponent extends BaseComponent {
  componentDidMount(newProps) {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  render() {
    return (
      <div className="global_modal">
        {/* <div className="upload_modal_wrapper"> */}
        <div onClick={this.props.closeModal} className="upload_overlay"></div>
        <div className="share_modal upload_modal">
          <div className="close_button" onClick={this.props.closeModal}>
            X
          </div>
          <div className="place_input place_title">{this.props.children}</div>
        </div>
      </div>
      // </div>
    );
  }
}
