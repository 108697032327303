import React from 'react';
import {BaseComponent} from '../../base-component';
import {PlacesController} from '../../controllers/placesController';
import Utilities from '../../utilities';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import ImageUploader from 'react-images-upload';
import AliceCarousel from 'react-alice-carousel';
import ProgressBar from 'react-bootstrap/ProgressBar';
import {Config} from '../../config';
import './style.css';
import Autocomplete from 'react-google-autocomplete';
import {PlaceTile} from '../../components/PlaceTile';
import marker_img from '../../assets/marker_map.png';
import marker_bad_img from '../../assets/marker_bad_map.png';
import marker_ok_img from '../../assets/marker_ok_map.png';
import {Features} from '../../components/Features';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default class Search extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      mounted: true,
      searchResults: [],
      markers: [],
      filterOpen: false,
      features: [],
      filter: {
        gRating: 0,
        gPrice: 0,
        gOpen: 0,
        tMarker: 0
      },
      openFilter: {
        date: {
          time: (new Date()).toLocaleTimeString(),
          day: (new Date()).getDay()
        },
        filter: false,
        yes: false
      },
      map_class: "in_map",
      page_name: "",
      page_num: 0,
      page_sum: 0,
      mobile_map: false
    };

    this.controller = new PlacesController();
    this.search = this.search.bind(this);
    this.writeSearchResult = this.writeSearchResult.bind(this);
    this.updateMap = this.updateMap.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.selectGFilter = this.selectGFilter.bind(this);
    this.selectGPrice = this.selectGPrice.bind(this);
    this.selectGOpen = this.selectGOpen.bind(this);
    this.toggleFeature = this.toggleFeature.bind(this);
    this.trackScrolling = this.trackScrolling.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.scrollToSearch = this.scrollToSearch.bind(this);
    this.changePage = this.changePage.bind(this);
    this.goToAddPlace = this.goToAddPlace.bind(this);
    this.enterPressed = this.enterPressed.bind(this);
    this.toggleMap = this.toggleMap.bind(this);
    this.scrollToTile = this.scrollToTile.bind(this);
  }

  async componentDidMount() {

    //document.addEventListener('scroll', this.trackScrolling);

    let search_text = localStorage.getItem("search_text");
    this.searchBox.value = search_text;
    await this.search();
    localStorage.removeItem("search_text");
    let features = await this.controller.getFeatures();

    this.setState({
      features: features.features
    });
  }

  componentWillReceiveProps(newProps) {
  }

  toggleFeature(event, index) {
    event.preventDefault();

    let features = this.state.features.slice(0);

    let toggleVal = !features[index].checked;

    features[index].checked = toggleVal;

    this.setState({
      features: features
    });
  }

  getFeatures(features) {
    let newFeatures = [];
    for (var i = 0; i < features.length; i++) {
      if (features[i].checked) {
        newFeatures.push(features[i].id);
      }
    }
    return JSON.stringify({features: newFeatures});
  }

  async search() {
    let searchText = this.searchBox.value;

    let features = this.getFeatures(this.state.features);

    if (searchText.length > 1) {

      let sortValue = this.sortValue.value;


      let searchFilter = JSON.stringify(this.state.filter);

      let openFilter = JSON.stringify(this.state.openFilter);

      let searchResults = await this.controller.search(
        searchText.trim(),
        1, searchFilter, features, openFilter, this.state.page_num);

      if (searchResults.success) {
        this.updateMap();
        this.setState({
          searchResults: searchResults.results,
          page_sum: this.getPageNum(searchResults.count),
          markers: []
        }, () => {
          this.updateMap();
          /* if(searchResults.results.length > 0)
            this.scrollToSearch(); */
        });
      } else {
        this.setState({
          searchResults: [],
          page_sum: 0,
          markers: []
        });
      }
    }
  }

  getPageNum(count) {
    let divide = parseInt(count / 10, 0);

    if (divide * 10 < count)
      divide++;

    return divide;
  }

  updateMap() {
    if (this.state.searchResults.length > 0) {
      let results = this.state.searchResults;
      let markers = [];

      // Center map around first result initially
      let position = {
        lat: parseFloat(results[0].lat),
        lng: parseFloat(results[0].lng)
      };
      this.map = new window['google'].maps.Map(document.getElementById('map'), {
        center: position,
        zoom: 17,
        mapTypeId: 'roadmap',
      });

      var infoWindow = new window["google"].maps.InfoWindow({});

      var bounds = new window["google"].maps.LatLngBounds();
      const that = this;
      for (let i = 0; i < results.length; i++) {
        position = {
          lat: parseFloat(results[i].lat),
          lng: parseFloat(results[i].lng)
        };

        let score = results[i].feature_sum;

        var marker = new window.google.maps.Marker({
          map: this.map,
          position: position,
          title: results[i].name,
          html: "<h2><a target='_blank' href='/place/" + results[i].id + "'>" + results[i].name + "</a></h2><div>" + results[i].address + "</div>",
          icon: score < 14 ? marker_bad_img : (score > 18 ? marker_img : marker_ok_img)
        });

        marker.addListener('click', function () {
          infoWindow.setContent(this.html);
          infoWindow.open(this.map, this);
          that.scrollToTile(i);
        });

        markers.push(marker);
        bounds.extend(marker.getPosition());
      }

      // Fit all to bounds
      if (results.length > 1) {
        this.map.fitBounds(bounds);
      }

      this.setState({
        markers: markers
      });
    }
  }

  scrollToTile(index) {
    const topPos = document.getElementById('place_tile_' + index).offsetTop;
    document.getElementById('search_page_left').scrollTop = topPos;
  }

  writeSearchResult(place, index) {
    const {features} = this.state;

    return (
      <PlaceTile index={index} place={place} source="Search" />
    );
  }

  toggleFilter() {
    this.setState({
      filterOpen: !this.state.filterOpen,
      page_num: 0
    });
  }

  searchFilter() {
    this.setState({
      filterOpen: !this.state.filterOpen,
      page_num: 0
    }, () => {
      if (!this.state.filterOpen) {
        this.search();
      }
    });
  }

  resetFilter() {
    let features = this.state.features;

    for (var i = 0; i < features.length; i++) {
      features[i].checked = false;
    }

    this.setState({
      filterOpen: !this.state.filterOpen,
      features,
      openFilter: {
        date: {
          time: (new Date()).toLocaleTimeString(),
          day: (new Date()).getDay()
        },
        filter: false,
        yes: false
      },
      filter: {
        gRating: 0,
        gPrice: 0,
        gOpen: 0,
        tMarker: 0
      },
    }, () => {
      if (!this.state.filterOpen) {
        this.search();
      }
    });
  }

  selectGFilter(gRating) {
    this.setState({
      filter: {
        ...this.state.filter,
        gRating
      }
    });
  }

  selectGPrice(gPrice) {
    this.setState({
      filter: {
        ...this.state.filter,
        gPrice
      }
    });
  }

  selectGOpen(gOpen) {
    this.setState({
      filter: {
        ...this.state.filter,
        gOpen
      },
      openFilter: {
        date: {
          time: (new Date()).toLocaleTimeString(),
          day: (new Date()).getDay()
        },
        filter: gOpen !== 0,
        yes: gOpen === 1
      }
    });
  }

  selectTMarker(tMarker) {
    this.setState({
      filter: {
        ...this.state.filter,
        tMarker
      }
    });
  }

  trackScrolling() {
    let scroll = this.checkMapScroll();
    let map_class = "in_map";

    switch (scroll) {
      case 0:
        map_class = "in_map";
        break;
      case 1:
        map_class = "fixed_map";
        break;
      case 2:
        map_class = "absolute_map";
        break;
      default: break;
    }

    this.setState({
      map_class
    });
  }

  scrollToSearch() {
    window.scrollTo(0, 220);
  }

  checkMapScroll() {
    let scroll_num = 0;
    let search_comp = document.getElementById('sort_element').bottom;

    let footer = document.getElementById("footer_container").getBoundingClientRect().bottom;

    scroll_num = footer < window.pageYOffset + window.innerHeight ? 2 : (search_comp < 1 ? 1 : 0);

    return scroll_num;
  }

  changePage(offset) {
    this.setState({
      page_num: this.state.page_num + offset
    }, () => {
      this.search();
    });
  }

  goToAddPlace() {
    this.props.history.push('/addplace');
  }

  enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.search();
    }
  }

  toggleMap() {
    this.setState({
      mobile_map: !this.state.mobile_map
    });
  }

  render() {
    return (
      this.state.mounted ?
        <div className="search_page">
          <div className="search_page_header">
            {this.state.filterOpen ?
              <div className="search_filter_drop">
                <div className="search_filter_top">
                  <div className="search_filter_left">
                    <div>Google Rating:
                      <div className="google_rating_filter filter_buttons">
                        <div className={"filter_button" + (this.state.filter.gRating === 0 ? " selected" : "")} onClick={() => this.selectGFilter(0)}>
                          Any
                        </div>
                        <div className={"filter_button" + (this.state.filter.gRating === 3 ? " selected" : "")} onClick={() => this.selectGFilter(3)}>
                          3+
                        </div>
                        <div className={"filter_button" + (this.state.filter.gRating === 3.5 ? " selected" : "")} onClick={() => this.selectGFilter(3.5)}>
                          3.5+
                        </div>
                        <div className={"filter_button" + (this.state.filter.gRating === 4 ? " selected" : "")} onClick={() => this.selectGFilter(4)}>
                          4+
                        </div>
                        <div className={"filter_button" + (this.state.filter.gRating === 4.5 ? " selected" : "")} onClick={() => this.selectGFilter(4.5)}>
                          4.5+
                        </div>
                      </div>
                    </div>
                    <div>Price:
                    <div className="google_price_filter filter_buttons">
                        <div className={"filter_button" + (this.state.filter.gPrice === 0 ? " selected" : "")} onClick={() => this.selectGPrice(0)}>
                          Any
                      </div>
                        <div className={"filter_button" + (this.state.filter.gPrice === 1 ? " selected" : "")} onClick={() => this.selectGPrice(1)}>
                          $
                      </div>
                        <div className={"filter_button" + (this.state.filter.gPrice === 2 ? " selected" : "")} onClick={() => this.selectGPrice(2)}>
                          $$
                      </div>
                        <div className={"filter_button" + (this.state.filter.gPrice === 3 ? " selected" : "")} onClick={() => this.selectGPrice(3)}>
                          $$$
                      </div>
                        <div className={"filter_button" + (this.state.filter.gPrice === 4 ? " selected" : "")} onClick={() => this.selectGPrice(4)}>
                          $$$$
                      </div>
                      </div>
                    </div>
                  </div>
                  <div className="search_filter_right">
                    <div>Open Now:
                      <div className="google_open_filter filter_buttons">
                        <div className={"filter_button" + (this.state.filter.gOpen === 0 ? " selected" : "")} onClick={() => this.selectGOpen(0)}>
                          Any
                        </div>
                        <div className={"filter_button" + (this.state.filter.gOpen === 1 ? " selected" : "")} onClick={() => this.selectGOpen(1)}>
                          Yes
                        </div>
                        <div className={"filter_button" + (this.state.filter.gOpen === 2 ? " selected" : "")} onClick={() => this.selectGOpen(2)}>
                          No
                        </div>
                      </div>
                    </div>
                    <div>TravelEZ Rating:
                      <div className="travelez_rating_filter filter_buttons">
                        <div className={"filter_button" + (this.state.filter.tMarker === 0 ? " selected" : "")} onClick={() => this.selectTMarker(0)}>
                          Any
                        </div>
                        <div className={"filter_button" + (this.state.filter.tMarker === 1 ? " selected" : "")} onClick={() => this.selectTMarker(1)}>
                          <img alt="travelez marker bad" src={marker_bad_img} />
                        </div>
                        <div className={"filter_button" + (this.state.filter.tMarker === 2 ? " selected" : "")} onClick={() => this.selectTMarker(2)}>
                          <img alt="travelez marker good" src={marker_ok_img} />
                        </div>
                        <div className={"filter_button" + (this.state.filter.tMarker === 3 ? " selected" : "")} onClick={() => this.selectTMarker(3)}>
                          <img alt="travelez marker great" src={marker_img} />
                        </div>
                      </div>
                      <div className="filter_buttons_wrapper">
                        <span className="button_close" onClick={this.toggleFilter}>X</span>
                        <div className="reset_button">
                          <button className="primary_button" onClick={this.resetFilter} >reset</button>
                        </div>
                        <div className="search_button">
                          <button className="primary_button" onClick={this.searchFilter}>search</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="search_filter_bottom">

                  <div>
                    Only show places with:
                    <Features showCheckBox={true} stairs={false} features={this.state.features} toggleFeature={this.toggleFeature} />
                  </div>
                </div>
              </div> : ""}
            <div className="search_input" ref={node => this.search_results = node}>
              <input onKeyPress={this.enterPressed} ref={node => this.searchBox = node} type="text" onChange={this.search} placeholder="Search place" />
            </div>
            <div id="sort_element" className="search_sort_filter">
              <div className="search_sort">
                <div>
                  <div>
                    <select ref={node => this.sortValue = node} onChange={this.search}>
                      <option value="-1">Sort By</option>
                      <option value="0">TravelEZ Rating</option>
                      <option value="1">Google User Rating</option>
                      <option value="2">Alphabetical: A to Z</option>
                      <option value="3">Alphabetical: Z to A</option>
                      <option value="4">Price: Low to High</option>
                      <option value="5">Price: Hight to Low</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="search_filter">
                <div onClick={this.toggleFilter} className="search_filter_select">
                  Filter By:
                  </div>
              </div>
            </div>
            <div className="mobile_map_icon">
              <span onClick={this.toggleMap} >{this.state.mobile_map ? "list" : "map"}</span>
            </div>
          </div>
          {this.state.searchResults.length === 0 ?
            <div className="empty_container">
              <div className="empty_content">
                No results found. Add a place here:<br /><br />
                <button className="primary_button" onClick={this.goToAddPlace}>Add a Place</button>
              </div>
            </div> : ""}
          <div className={"search_page_results " + (this.state.mobile_map ? "map_showing" : "")}>
            <div ref={node => this.refResults = node} id="search_page_left" className="search_page_left">

              {this.state.searchResults.length > 0 ?
                <div className="search_pager_wrapper">
                  <div className="search_pager_content">
                    <div className="search_pager_center">
                      {this.state.page_num !== 0 ?
                        <div onClick={() => this.changePage(-1)} className="prev"> &lt;Prev </div>
                        : ""}
                      <div className="page_num">Page {this.state.page_num + 1} of {this.state.page_sum}</div>
                      {this.state.page_num + 1 < this.state.page_sum ?
                        <div className="next" onClick={() => this.changePage(1)}>Next &gt;</div>
                        : ""}
                    </div>
                  </div>
                </div> : ""}
              {this.state.searchResults.length > 0 ?
                <div id="results_container">
                  {this.state.searchResults.map(this.writeSearchResult)}
                </div> : ""
              }
            </div>
            <div className={"search_page_right " + (this.state.mobile_map ? "mobile_map" : "")}>
              <div className="place_map_wrap ">
                {this.state.page_sum > 0 ?
                  <div id='map' /> : ""}
              </div>
            </div>
          </div>
        </div> : ""
    );
  }
}
