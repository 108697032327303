import React from "react";
import "./style.css";
import { BaseComponent } from "../../base-component";
import NoAuthController from "../../controllers/noAuthController";
import { PlacesController } from "../../controllers/placesController";
import { PlaceTile } from "../../components/PlaceTile";

export default class Profile extends BaseComponent {
  state = {
    favorites: [],
    frToggle: false,
    user: {
      email: null,
      nickname: null
    },
    message: "",
    page: 0,
    page_sum: 10,
    stairs_num: 0,
    stairOptions: {
      deployable: false,
      entrance: false,
      lift: false
    },
    city: null,
  };

  constructor(props) {
    super(props);

    this.controller = new PlacesController();

    this.signOut = this.signOut.bind(this);
    this.toggleFavoritesReviews = this.toggleFavoritesReviews.bind(this);
    this.saveProfile = this.saveProfile.bind(this);
    this.goToSearch = this.goToSearch.bind(this);
    this.goToAddPlace = this.goToAddPlace.bind(this);
    this.selectSituation = this.selectSituation.bind(this);
    this.getReviews = this.getReviews.bind(this);
    this.toggleStairs = this.toggleStairs.bind(this);
  }

  async componentDidMount() {
    let date = {
      time: new Date().toLocaleTimeString(),
      day: new Date().getDay()
    };
    let favorites = await this.controller.getFavorites(JSON.stringify(date));
    let places = await this.controller.getAddedPlaces(
      JSON.stringify(date),
      this.state.page
    );
    let user = await this.controller.getUser();

    let page_sum = parseInt(parseInt(places.count, 0) / 10);

    if (page_sum < parseInt(places.count, 0) / 10) page_sum++;

    this.setState({
      favorites: favorites.favorites,
      user: user.user,
      places: places.places,
      page_sum,
      situation: user.user.situation
        ? JSON.parse(user.user.situation)[0]
        : null,
      public: user.user.public,
      stairs_num: user.user.stairs ? user.user.stairs : 0,
      stairOptions: user.user.stairOptions
        ? JSON.parse(user.user.stairOptions)
        : this.state.stairOptions,
      city: user.user.city
    });
  }

  async getReviews(num) {
    const page = this.state.page + num;
    const date = {
      time: new Date().toLocaleTimeString(),
      day: new Date().getDay()
    };
    const places = await this.controller.getAddedPlaces(
      JSON.stringify(date),
      page
    );

    let page_sum = parseInt(parseInt(places.count, 0) / 10);
    if (page_sum < parseInt(places.count, 0) / 10) {
      page_sum++;
    }

    this.setState({
      places: places.places,
      page_sum,
      page
    });
  }

  signOut() {
    window.mixpanel.track("Signed Out", { source: "Profile" });
    // Shouldn't we await here?
    NoAuthController.signOut();

    this.props.onSignOut(() => {
      this.props.history.push("/");
    });
  }

  mapFavorites(favorite, index) {
    // TODO: Fix this hack
    favorite.accessible = true;

    return <PlaceTile place={favorite} source="Profile/Favorites" />;
  }

  mapReviews(review, index) {
    // TODO: Fix this hack
    review.accessible = true;

    return <PlaceTile place={review} source="Profile/Map" />;
  }

  toggleFavoritesReviews() {
    this.setState({
      frToggle: !this.state.frToggle
    });
  }

  async saveProfile() {
    const nickname = this.nickname.value !== "" ? this.nickname.value : null;

    const {situation, stairOptions, stairs_num, city} = this.state;

    localStorage.setItem("city", city);

    await this.controller.saveProfile(
      nickname,
      situation,
      this.public.checked,
      stairOptions,
      stairs_num,
      city,
    );
    await this.controller.getUser();
    this.setState({
      message: "Profile Updated!"
    });
  }

  goToSearch() {
    this.props.history.push("/");
  }

  goToAddPlace() {
    this.props.history.push("/addplace");
  }

  selectSituation(event) {
    let value = event.target.value;
    this.setState({
      situation: value !== -1 ? value : null
    });
  }

  handleSelectCity = e => {
    const {value} = e.target;

    this.setState({city: value !== -1 ? value : null});
  }

  modifyStairs(num) {
    let temp = this.state.stairs_num + num;
    if (temp >= 0 && temp <= 5) {
      this.setState({
        stairs_num: temp
      });
    }
  }

  toggleStairs(name) {
    this.setState({
      stairOptions: {
        ...this.state.stairOptions,
        [name]: !this.state.stairOptions[name]
      }
    });
  }

  render() {
    const { user, places = [], city } = this.state;

    return (
      <div className="profile_content">
        <div className="profile_content_left">
          <h1>Profile</h1>

          <a className="signout_button" onClick={this.signOut}>
            Sign Out
          </a>
          <div className="profile_content_rows">
            <div className="profile_row">
              Email: <span>{user.email}</span>
            </div>
            <div className="profile_row">
              Nickname:
              <span>
                <input
                  ref={node => (this.nickname = node)}
                  value={user.nickname}
                  type="text"
                  className="input_normal"
                  placeholder="Enter a nickname"
                  onChange={event =>
                    // TODO: User deepCopy
                    this.setState({
                      user: {
                        ...user,
                        nickname: event.target.value
                      }
                    })
                  }
                />
              </span>
            </div>
            <div>
              <div>Select Your City</div>
              <select
                value={city}
                onChange={this.handleSelectCity}
              >
                <option value={-1}>Select city...</option>
                <option value="New York">
                  New York
                </option>
                <option value="Boston">
                  Boston
                </option>
              </select>
              <div>Tell us a little about your needs:</div>
              <select
                value={this.state.situation}
                onChange={this.selectSituation}
              >
                <option value={-1}>Select One...</option>
                <option value="I use a manual wheelchair">
                  I use a manual wheelchair
                </option>
                <option value="I use a power wheelchair">
                  I use a power wheelchair
                </option>
                <option value="I use a scooter">I use a scooter</option>
                <option value="I use an assistive device besides wheelchair">
                  I use an assistive device besides wheelchair/scooter
                </option>
                <option value="I am a slow walker">I am a slow walker</option>
                <option value="I use a stroller">I use a stroller</option>
                <option value="I am a caretaker">I am a caretaker</option>
                <option value="Other">Other</option>
                <option value="Prefer not to say ">Prefer not to say</option>
              </select>
              <div className="public_text">
                Do you want your accessibility needs to be public? Making public
                adds context to your posts to help other users make better
                decisions. We recommend keeping public!
              </div>

              <div className="public_check_wrapper">
                <input
                  id="privateCheck"
                  defaultChecked={this.state.user.public}
                  type="checkbox"
                  ref={node => (this.public = node)}
                />
                <label className="public_label" for="privateCheck">
                  Public
                </label>
              </div>
            </div>
            <div>
              <br />
              What do you count as an Accessible Entry? Check the boxes next to
              the entry options that you consider to be accessible.
              <div>
                <div className="number_stairs_wrapper">
                  <div className="number_stairs">
                    <div className="feature_name">
                      Maximum stairs you can climb:{" "}
                    </div>
                    <div className="number_buttons">
                      <div
                        className="circle_button"
                        onClick={() => {
                          this.modifyStairs(-1);
                        }}
                      >
                        -
                      </div>
                      <div className="stairs_number">
                        {this.state.stairs_num}
                        {this.state.stairs_num === 5 ? "+" : ""}
                      </div>
                      <div
                        onClick={() => {
                          this.modifyStairs(1);
                        }}
                        className="circle_button"
                      >
                        +
                      </div>
                    </div>
                  </div>
                  <div className="stair_features">
                    <div
                      onClick={event => {
                        this.toggleStairs("deployable");
                      }}
                      className="feature_choice"
                      style={{ width: "100%" }}
                    >
                      <div className="feature_name">
                        <label for="deployable">Deployable Ramp</label>
                      </div>
                      <div className="radio_choices">
                        {this.state.stairOptions.deployable ? (
                          <input type="checkbox" checked={true} />
                        ) : (
                          <input type="checkbox" checked={false} />
                        )}
                      </div>
                    </div>
                    <div
                      onClick={event => {
                        this.toggleStairs("entrance");
                      }}
                      className="feature_choice"
                      style={{ width: "100%" }}
                    >
                      <div className="feature_name">
                        <label for="entrance">
                          Staff-Operated Side Entrance
                        </label>
                      </div>
                      <div className="radio_choices">
                        {this.state.stairOptions.entrance ? (
                          <input type="checkbox" checked={true} />
                        ) : (
                          <input type="checkbox" checked={false} />
                        )}
                      </div>
                    </div>
                    <div
                      onClick={event => {
                        this.toggleStairs("lift");
                      }}
                      className="feature_choice"
                      style={{ width: "100%" }}
                    >
                      <div className="feature_name">
                        <label for="lift">Lift</label>
                      </div>
                      <div className="radio_choices">
                        {this.state.stairOptions.lift ? (
                          <input type="checkbox" checked={true} />
                        ) : (
                          <input type="checkbox" checked={false} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile_row button_row">
              <button className="primary_button" onClick={this.saveProfile}>
                Save Profile
              </button>
            </div>
            <div className="profile_row message_row">
              <div>{this.state.message}</div>
            </div>
            <div className="profile_row cp">
              <a href="/cp">Change Password</a>
            </div>
          </div>
        </div>
        <div className="profile_content_right">
          <div className="impact_content">
            <h1>My Impact</h1>
            <div className="cards">
              <div className="card">
                <div className="card_title">Reviews</div>
                <div className="card_stats">{user.num_reviews}</div>
                <div className="card_subtitle">all time</div>
              </div>
              <div className="card last">
                <div className="card_title">Photos</div>
                <div className="card_stats">{user.num_photos}</div>
                <div className="card_subtitle">all time</div>
              </div>
            </div>
          </div>

          <div
            className="profile_buttons"
            onClick={this.toggleFavoritesReviews}
          >
            <div className="favorites_button">Favorites</div>
            <div className="reviews_button">Reviews</div>
          </div>
          {!this.state.frToggle ? (
            <div className="favorites">
              <div className="add_favorite">
                <button onClick={this.goToSearch} className="primary_button">
                  Add a Favorite
                </button>
              </div>
              {this.state.favorites.map(this.mapFavorites)}
            </div>
          ) : (
            <div className="reviews">
              <div className="add_review">
                <button className="primary_button" onClick={this.goToAddPlace}>
                  Add a Review
                </button>
              </div>
              {this.state.places && this.state.places.length > 0 ? (
                <div className="search_pager_wrapper">
                  <div className="search_pager_content">
                    <div className="search_pager_center">
                      {this.state.page !== 0 ? (
                        <div
                          onClick={() => this.getReviews(-1)}
                          className="prev"
                        >
                          {" "}
                          &lt;Prev{" "}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="page_num">
                        Page {this.state.page + 1} of {this.state.page_sum}
                      </div>
                      {this.state.page + 1 < this.state.page_sum ? (
                        <div
                          className="next"
                          onClick={() => this.getReviews(1)}
                        >
                          Next &gt;
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.places && this.state.places.map(this.mapReviews)}
            </div>
          )}
        </div>
      </div>
    );
  }
}
