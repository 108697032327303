import React from 'react';
import ReactDOM from 'react-dom';
import { BaseComponent } from '../../base-component';
import './style.css';
import google_img from '../../assets/g.png';

export class Rating extends BaseComponent {
    constructor(props) {
        super(props);
     
        this.state = {
          rating: (props.rating  / 5 ) * 100,
          editable: props.editable,
          hoverRating: (props.rating  / 5 ) * 100
        };

        this.updateRating = this.updateRating.bind(this);
        this.hoverOverRating = this.hoverOverRating.bind(this);
      }

      componentWillReceiveProps(newProps) {
        this.setState({
          rating: (newProps.rating  / 5 ) * 100,
        });
      }

      updateRating(rating) {
        console.log(rating);
        this.props.onEdited(rating);
      }

      hoverOverRating(rating) {
        console.log(rating);
        this.setState({
          hoverRating: (rating / 5) * 100
        });
      }
     
      render() {
        return (
          <div className="google_rating">
            <div className="google_rating_img">
              {!this.state.editable && !this.props.staff ? <img alt="google icon" src={google_img} /> : ''}
            </div>
            <div className={"star-rating" + (this.state.editable ? '-editable': '')} title={this.state.rating + "%"}>
            {this.state.editable ?   
              <div className="back-stars-editable">
                  <i className="fa fa-star" onMouseOver={()=>this.hoverOverRating(1)} aria-hidden="true"></i>
                  <i className="fa fa-star" onMouseOver={()=>this.hoverOverRating(2)} aria-hidden="true"></i>
                  <i className="fa fa-star" onMouseOver={()=>this.hoverOverRating(3)} aria-hidden="true"></i>
                  <i className="fa fa-star" onMouseOver={()=>this.hoverOverRating(4)} aria-hidden="true"></i>
                  <i className="fa fa-star" onMouseOver={()=>this.hoverOverRating(5)} aria-hidden="true"></i>
              </div>  :  
              <div className="back-stars">
                  <i className="fa fa-star" aria-hidden="true"></i>
                  <i className="fa fa-star" aria-hidden="true"></i>
                  <i className="fa fa-star" aria-hidden="true"></i>
                  <i className="fa fa-star" aria-hidden="true"></i>
                  <i className="fa fa-star" aria-hidden="true"></i>
              </div>}
              {this.state.editable ?    
              <div className="front-stars-editable" style={{width: this.state.hoverRating + "%"}}>
                  <i className="fa fa-star" onMouseOver={()=>this.hoverOverRating(1)} onClick={()=>this.updateRating(1)} aria-hidden="true"></i>
                  <i className="fa fa-star" onMouseOver={()=>this.hoverOverRating(2)} onClick={()=>this.updateRating(2)} aria-hidden="true"></i>
                  <i className="fa fa-star" onMouseOver={()=>this.hoverOverRating(3)} onClick={()=>this.updateRating(3)} aria-hidden="true"></i>
                  <i className="fa fa-star" onMouseOver={()=>this.hoverOverRating(4)} onClick={()=>this.updateRating(4)} aria-hidden="true"></i>
                  <i className="fa fa-star" onMouseOver={()=>this.hoverOverRating(5)} onClick={()=>this.updateRating(5)} aria-hidden="true"></i>
              </div> : 
            <div className="front-stars" style={{width: this.state.rating + "%"}}>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
            </div> }
            </div>  
          </div>
        );
    }
}
