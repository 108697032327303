import React from 'react';
import { BaseComponent } from '../../base-component';
import { PlacesController } from '../../controllers/placesController';
import NoAuthController from "../../controllers/noAuthController";
import Utilities from "../../utilities";
import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Rating } from '../../components/Rating';
import { OpeningHours } from '../../components/OpeningHours';
import { Score } from '../../components/Score';
import ModalComponent from '../../components/Modal';
import { Config } from '../../config';
import AliceCarousel from 'react-alice-carousel';
import ImageUploader from 'react-images-upload';
import { cloneDeep, get } from 'lodash';
import { Features } from '../../components/Features';
import SignInForm from '../../components/SignInForm';
import OpenTableLogo from '../../assets/open_table.jpg'
import ProgressBar from 'react-bootstrap/ProgressBar';

// TODO: Refactor
export default class Place extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
          id: props.match.params.id,
          features: [],
          comments: {
            text: "",
            date: null,
            name: null
          },
          images: {
            photos: [],
            tags: [],
            descriptions: []
          },
          rating: 0,
          feature_list: [],
          reviews: [],
          favorite: false,
          shareOpen: false,
          intervalId: 0,
          feature_sum: 0,
          official: false,
          photoModalOpen: false,
          selected_photo: {
            image: null,
            tag: null,
            description: null
          },
          mounted: false,
          uploadModalOpen: false,
          uploading: false,
          upload: {
            percentCompleted: 0,
            image: 1
          },
          upload_pictures: [],
          upload_pictures_descriptions: [],
          upload_pictures_tags: [],
          files: [],
          rotate: 0,
          currentIndex: 0,

          message: "",
          errors: {
            e: false,
            p: false
          },
        };

        this.map = null

        this.controller = new PlacesController();

        this.writeAllFeatures = this.writeAllFeatures.bind(this);
        this.addReview = this.addReview.bind(this);
        this.openShare = this.openShare.bind(this);
        this.goToMobileGoogle = this.goToMobileGoogle.bind(this);
        this.drawImage = this.drawImage.bind(this);
        this.setPictureModal = this.setPictureModal.bind(this);
        this.openPhoto = this.openPhoto.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.openUpload = this.openUpload.bind(this);
        this.rotateImage = this.rotateImage.bind(this);

        this.modalStyles = {
          content : {
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 5 + '%',
            backgroundColor: '#009ba4',
            padding: 30 + "px",
            zIndex: 20000
          }
        };
      }

      async componentDidMount() {
        let features = await this.controller.getFeatures();

        let date = {
          time: (new Date()).toLocaleTimeString(),
          day: (new Date()).getDay()
        }

        let res = await this.controller.getPlace(this.state.id, JSON.stringify(date));

        let place = res.place[0];
        let favorite = false;
        let isOwner = false;
        if(this.props.isLoggedIn) {
          favorite = await this.controller.isFavorite(this.state.id);
          if(place.ownerId === localStorage.getItem('userId')) {
            isOwner = true;
          }
        }

        let reviews = await this.controller.getReviews_Place(this.state.id);

        let position = {lat: parseFloat(place.lat), lng: parseFloat(place.lng)};

        this.map = new window['google'].maps.Map(document.getElementById('map'), {
          center: position,
          zoom: 17,
          mapTypeId: 'roadmap',
        });

        var marker = new window.google.maps.Marker({
          map: this.map,
          position: position
        });

        marker.setMap(this.map);

        document.getElementById('address').innerHTML = place.address;

        let d = (new Date(place.createdAt * 1000)).toDateString();


        let name = place.email[0]+place.email.split('@')[1][0];
        if(place.nickname) {
          name = place.nickname
        }

        let situation = "";
        if(place.situation) {
          situation = JSON.parse(place.situation)[0]
        }

        let images = JSON.parse(place.images);

        let photos = images ? images.photos : [];
        let tags = images ? (images.tags ? images.tags : []) : [];
        let descriptions = images ? (images.descriptions ? images.descriptions : []) : [];

        if(place.others_images) {
          let others_images = JSON.parse(place.others_images);
          photos = photos.concat(others_images ? others_images.photos : [])
          tags = tags.concat(others_images ? others_images.tags : [])
          descriptions = descriptions.concat(others_images ? others_images.descriptions : [])
        }

        let theFeatures = features.features;
        for(var i = 0; i < theFeatures.length; i++) {
          if(place.features.indexOf(theFeatures[i].id) !== -1) {
            theFeatures[i].checked = true;
          } else {
            theFeatures[i].checked = false;
          }
        }

        this.setState({
          address: place.address,
          features: place.features,
          features_map: place.features,
          feature_list: theFeatures,
          feature_sum: place.feature_sum,
          mapLink: place.mapLink,
          comments: {
            name: name,
            text: place.comments,
            date: d,
            situation,
            public: place.public
          },
          images: {
            photos,
            tags,
            descriptions
          },
          name: place.name,
          price_level: place.price,
          rating: parseFloat(place.rating),
          date: d,
          website: place.website,
          phone: place.phone,
          reviews: reviews.reviews,
          hours: place.hours,
          favorite: favorite.favorite,
          open_now: place.open_now,
          close_time: place.close_time,
          open_time: place.open_time,
          opening_hours: place.hours,
          official: place.official,
          staffRating: place.staffrating,
          mounted: true,
          image: place.image,
          isOwner,
          stairOptions: place.stairOptions ? JSON.parse(place.stairOptions) : {
            deployable: false,
            entrance: false,
            lift: false
          },
          stairs: place.stairs
        });
      }

     addNewPhotos = async (event) => {
        await this.props.checkToken(()=>{});

        if(this.props.isLoggedIn) {
            this.setState({ uploading: true });

            var config = {
              onUploadProgress: (progressEvent) => {
                this.setState(prevState => ({
                  upload: {
                    percentCompleted: Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    ),
                    image: prevState.upload.image
                  }
                }))
              }
            };

            // Upload photos first

            let photosArr = [];
            for(var i = 0; i < this.state.files.length; i++) {
              const formData = new FormData();
              formData.append('file', this.state.files[i]);

              this.setState({
                upload: {...this.state,
                  image: i + 1
                }
              });

              let photoUrl = await this.controller.uploadPhotos(formData, config);
              photoUrl && photosArr.push(photoUrl.data.Location);
            }

            let data = {
              images: JSON.stringify({
                photos: photosArr,
                tags: this.state.upload_pictures_tags,
                descriptions: this.state.upload_pictures_descriptions}),
              placeId: this.state.id
            }

            let response = await this.controller.updatePhotos(data);

            if(response.success) {
              this.setState({ uploading: false });

              window.location.reload();
            } else {
              this.setState({
                uploading: false,
                message: response.error
              });
            }
        } else {
          this.props.history.push('/signin');
        }
      }

      async componentWillReceiveProps(newProps) {
        let favorite = false;
        if(newProps.isLoggedIn) {
          favorite = await this.controller.isFavorite(this.state.id);
        }
        this.setState({
          favorite: favorite.favorite
        });
      }

      getCuisine(cuisine_arr, cuisines) {
        let cuisine = '';
        if(cuisine_arr){
          cuisine_arr = JSON.parse(cuisine_arr);
          for(var i = 0; i < cuisines.length; i++) {
            for(var j = 0; j < cuisine_arr.length; j++) {
              if(cuisine_arr[j] === cuisines[i].id) {
                if(cuisine !== '')
                  cuisine += ', ';
                cuisine += cuisines[i].name
              }
            }

          }
        }
        if(cuisine === '')
          cuisine = null;

        return cuisine;
      }

      getFeatureIds(features) {
        let featuresArr = [];

        for(var i = 0; i < features.length; i++) {
          featuresArr.push(features[i].id)
        }

        return featuresArr;
      }

      setPictureModal(index, event) {
        const isMobile = window.innerWidth <= 768;

        if (isMobile) {
          return;
        }

        event.preventDefault();
        this.setState({
          photoModalOpen: true,
          selected_photo: {
            image: this.state.images.photos[index],
            tag: this.state.images.tags[index],
            description: this.state.images.descriptions[index],
            width: 'calc(100% - 80px)',
            height: 'auto',
            margin_top: 0
          }
        })
      }

      openPhoto() {
        this.setState({
          photoModalOpen: false,
          rotate: 0,
          wrapperHeight: 'auto',
          marginLeft: 0
        })
      }

      openUpload() {
        this.setState({
          uploadModalOpen: !this.state.uploadModalOpen
        })
      }

      editPlace = () => {
        const {name, id} = this.state;
        window.mixpanel.track("Edit Place Clicked", {source: "Place", id, name});

        window.location.replace('/addplace/' + this.state.id)
      }

      drawImage(picture, index) {
        let tag = this.getTag(parseInt(this.state.images.tags[index], 0))
        return (
          <div className="pictures_wrapper" onClick={(event)=>this.setPictureModal(index, event)} style={{width: '100%'}}>
              <div>
                  <div className="place_image" key={index + "place"} style={{width: '100%'}}>
                    <span className="helper"></span>
                    <img alt="place_image" className="upload_image" src={picture} style={{maxHeight: '100%', maxWidth: '100%'}} />

                </div>
                <div className="picture_tags">
                    {tag}
                  </div>
              </div>
          </div>
        )
      }

      getTag(tag_index) {
        switch(tag_index) {
          case 0:
            return "Other";
          case 1:
            return "Entrance";
          case 2:
            return "Bathroom";
          case 3:
            return "Tables/Chairs";
          case 4:
            return  "Overview of Space";
          default: return "Other";
        }
      }

      renderPrice = priceLevel => (
        <div>
          Price:
          <span>{' '} {"$".repeat(priceLevel)}</span>
          <span className="placeholder">
            {priceLevel < 4 && "$".repeat(4 - priceLevel)}
          </span>
        </div>
      );

      writeAllFeatures(feature, index) {

        let selected = this.state.features_map.indexOf(feature.id) !== -1;

        return (
          <div className="selected_features">
            <span className="feature_icon">{selected ?
              <span className="check"><FontAwesomeIcon icon="check"  /></span> :
              <span className="ex"><FontAwesomeIcon icon="times"  /></span>}</span>
            <div className="feature_name">{feature.name}</div>
          </div>
        );
      }

      addFavorite = async add => {
        const {id, name} = this.state;

        window.mixpanel.track("Add to Favorites Clicked", {id, name, source: "Place"});

        await this.props.checkToken(()=>{});
        if (this.props.isLoggedIn) {
          await this.controller.addFavorite(id, add);

          this.setState({favorite: add});
        } else {
          this.props.history.push('/signin');
        }
      }

      async addReview() {
        await this.props.checkToken(()=>{});
        if(this.props.isLoggedIn) {
          let review = this.review.value;
          let res = await this.controller.addReview(this.state.id, review);
          let reviews = await this.controller.getReviews_Place(this.state.id);
          this.review.value = "";
          this.setState({
            reviews: reviews.reviews
          });
        } else {
          this.props.history.push('/signin');
        }
      }

      writeReview(review) {
        let date = (new Date(review.createdAt * 1000)).toDateString();

        let name = "";
        let nickname = "";
        if(review.nickname) {
          nickname = review.nickname
          name = nickname[0];
        } else {
          name = review.email[0]+review.email.split('@')[1][0];
        }

        let situation = "";
        if(review.public) {
          if(review.situation)
            situation = JSON.parse(review.situation)[0]
        }

        return (
          <div className="one_review">
            <div className="one_review_name_wrapper">
              <div className="one_review_name">
                {name}
              </div>
              <div className="author_details">
                <div className="one_review_nickname">{nickname}</div>
                <div className="situation">{situation}</div>
                <div className="one_review_date">
                  {date}
                </div>
              </div>
            </div>
            <div className="one_review_comment">
              {review.review}
            </div>
          </div>
        );
      }

      openShare() {
        this.setState({
          shareOpen: !this.state.shareOpen,
          marginLeft: '0px',
          rotate: 0
        });
      }

      copyToClipboard() {
        var copyText = document.getElementById("copy_link");

        /* Select the text field */
        copyText.select();

        /* Copy the text inside the text field */
        document.execCommand("copy");

        /* Alert the copied text */
        alert("Copied the text: " + copyText.value);
      }

      // TODO: This should scroll to the element not to the bottom of the page
      addReviewScroll = () => {
        if (this.props.isLoggedIn) {
          window.scroll(0, window.document.body.scrollHeight);
        } else {
          window.scrollTo(0, 0)
          const {pathname} = this.props.location;
          this.props.history.push('/signin', {path: pathname.substr(1)});
        }
      }

      goToMobileGoogle() {
        window.scroll(0, document.getElementById('google_content').offsetTop);

      }

      onDrop(files) {
        const that = this;

        var reader = new FileReader();
        function readFile(index) {
          if( index >= files.length ) {
            that.uploader.state.files = [];
            that.uploader.state.pictures = [];
            return;
          };
          var file = files[index];
          reader.onload = function(e) {
            // get file content
            var bin = e.target.result;

            let upload_pictures = that.state.upload_pictures.slice(0);
            upload_pictures.push(bin);

            let upload_pictures_tags = that.state.upload_pictures_tags.slice(0);
            upload_pictures_tags.push("0");

            let upload_pictures_descriptions = cloneDeep(that.state.upload_pictures_descriptions)
            upload_pictures_descriptions.push("");

            that.setState({
              upload_pictures,
              files: files,
              upload_pictures_tags,
              upload_pictures_descriptions
            }, ()=>{
              // do sth with bin
              readFile(index+1)
            });
          }
          reader.readAsDataURL(file);
        }
        readFile(0);
    }

      drawUploadImage(picture, index) {
        return (
          <div className="pictures_wrapper">
              <div>
                  <div className="place_image" key={index + "place"}>
                    <span className="helper"></span>
                    <img alt="place_image" className="upload_image" src={picture} />
                </div>
              </div>
              <div className="picture_tags">
                <select value={this.state.upload_pictures_tags[index]} onChange={(event)=>this.updateTags(event, index)}>
                  <option value={0}>Other</option>
                  <option value={1}>Entrance</option>
                  <option value={2}>Bathroom</option>
                  <option value={3}>Tables/Chairs</option>
                  <option value={4}>Overview of Space</option>
                </select>
              </div>
              <div>
                <textarea placeholder="Add a description" value={this.state.upload_pictures_descriptions[index]} className="image_description"
                onChange={(event)=>this.updateDescriptions(event, index)} maxLength="100">

                </textarea>
                <button onClick={()=>{this.removePhoto(index)}}>Remove</button>
              </div>
          </div>
        )
      }

      updateTags(event, index) {
        let upload_pictures_tags = this.state.upload_pictures_tags.slice(0);
        upload_pictures_tags[index] = event.target.value;
        this.setState({
          upload_pictures_tags
        });
      }

      updateDescriptions(event, index) {
        let upload_pictures_descriptions = cloneDeep(this.state.upload_pictures_descriptions);
        upload_pictures_descriptions[index] = event.target.value;
        this.setState({
          upload_pictures_descriptions
        });
      }

      removePhoto(index) {
        let upload_pictures =  cloneDeep(this.state.upload_pictures);
        let upload_pictures_tags = cloneDeep(this.state.upload_pictures_tags);
        let upload_pictures_descriptions = cloneDeep(this.state.upload_pictures_descriptions);
        let files = cloneDeep(this.state.files);

        upload_pictures.splice(index, 1);
        upload_pictures_tags.splice(index,1);
        upload_pictures_descriptions.splice(index,1);
        files.splice(index,1);

        this.setState({
          upload_pictures,
          upload_pictures_tags,
          upload_pictures_descriptions,
          files
        });
      }

      rotateImage() {
        let rotate = this.state.rotate === 270 ? 0 : this.state.rotate + 90;
        let marginLeft = 0;
        let wrapperHeight = 'auto';

        if(rotate === 90 || rotate === 270 ) {
          marginLeft = 80;
          wrapperHeight = '600px'
        }

        this.setState({
          rotate,
          marginLeft,
          wrapperHeight
        });
      }

      handleSlideChanged = (e) => {
        const {currentIndex} = this.state;
        const newIndex = e.item;
        if (newIndex > currentIndex) {
          window.mixpanel.track("Next Picture", {source: "Place"})
        } else {
          window.mixpanel.track("Previous Picture", {source: "Place"})
        }

        setTimeout(100, () => this.setState({ currentIndex: e.item }));
      }

      handleSignInClick = async (email, password) => {
        window.mixpanel.track("Sign In Clicked", { source: "Place", email });
        
        let message = "";
        
        // TODO: Refactor
        let errors = {
          e: !email,
          p: !password
        };
    
        let valid = true;
        if (!email || !password) {
          valid = false;
          message = "All fields are required";
        }
    
        if (valid && !Utilities.validateEmail(email)) {
          valid = false;
          message = "Email is not in the appropriate format";
          errors.e = true;
        }
    
        this.setState({ errors });
    
        if (valid) {
          const response = await NoAuthController.login(email, password);
          response.success &&
            window.mixpanel.identify(get(response, "data.userId"));
    
          window.mixpanel.track("Signed In", { source: "Place" });
    
          if (response.success) {
            this.props.onSignIn(() => {
              window.location.reload();
            });
          } else {
            this.setState({
              message: response.error
            });
          }
        } else {
          this.setState({
            message
          });
        }
      };
    
      handleRegisterClick = () => {
        window.mixpanel.track("Register Clicked", { source: "Place" });
    
        this.props.history.push("/register");
      };

      renderOverLay = () => {
        const {message, errors} = this.state;

        return (
          <div className="overlay">
            <div className="prompt_container">
              <div className="prompt">Log in or create a free TravelEZ account to see more accessibility pictures, reviews, and details.</div>
              <SignInForm 
                onSignInClick={this.handleSignInClick}
                onRegisterClick={this.handleRegisterClick}
                message={message}
                errors={errors}
              />
            </div>
          </div>
        )
      }

      render() {
        const {price_level, images} = this.state;
        const {isLoggedIn} = this.props;
        const numberOfImages = get(images, 'photos.length') || 0;
        const {innerWidth} = window;
        const buttonsEnabled =
          (innerWidth < 767 && numberOfImages > 1)
          || ((innerWidth >= 767 && innerWidth < 1024) && numberOfImages > 3) || numberOfImages > 5;

        return (
          !isLoggedIn 
          ? this.renderOverLay()
          :
            <div className="place_main">
            {this.state.shareOpen && <ModalComponent closeModal={this.openShare}>
              <div className="share_modal">
                <h2>Share A Link to This Place</h2>

                <div>
                  <input type="text" id="copy_link"
                  value={`${window.location.protocol}//${window.location.host}/place/${this.state.id}`} />
                </div>
                <div>
                  <button className="copy_button" onClick={this.copyToClipboard}>Copy Link</button>
                </div>
              </div>
            </ModalComponent>
            }
            {this.state.photoModalOpen && <ModalComponent  closeModal={this.openPhoto} >
              <div>
                  <div className="image_rotate">
                    <FontAwesomeIcon onClick={this.rotateImage} icon="undo" />
                  </div>

                <div className="share_modal picture_modal">
                  <div style={{margin: 'auto', width: '600px', height: this.state.wrapperHeight ? this.state.wrapperHeight : 'auto'}}>
                    <img ref={node => this.selected_photo = node} alt="place_photo" style={{transform: 'rotate(' + this.state.rotate + 'deg)', width: '600px', marginTop: this.state.marginLeft ? this.state.marginLeft : '0px'}}
                    src={this.state.selected_photo.image} />
                  </div>
                  <div className="tag">
                    Tag: {this.getTag(parseInt(this.state.selected_photo.tag, 0))}
                  </div>
                  <div>
                    Description: {this.state.selected_photo.description}
                  </div>
                </div>
              </div>
            </ModalComponent>
            }
            {this.state.uploadModalOpen && <ModalComponent modalOpen={this.state.uploadModalOpen} closeModal={this.openUpload} >
            <div className="photos_upload_modal">
                    <div className="place_input place_title">
                    Upload any photos that will help fellow TravelEZers in making their plans (e.g. entrance or restroom grab bars).
                    {this.state.upload_pictures.length > 0 ?
                      <div className="upload_images">
                          <h2>Pictures to upload</h2>
                          <div className="upload_images_wrapper">
                          <AliceCarousel
                                responsive={{
                                  0: { items: 1 },
                                  767: { items: 3 },
                                  1024: { items: 4 },
                              }}
                                buttonsDisabled={true}
                                mouseDragEnabled
                                items = {this.state.upload_pictures.map((picture, index) => {return this.drawUploadImage(picture, index)})}
                                >
                            </AliceCarousel>
                          </div>
                      </div> : ""}
                      <ImageUploader
                          ref={node => this.uploader = node}
                          withIcon={true}
                          buttonText='Choose images'
                          onChange={this.onDrop}
                          imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                          maxFileSize={5242880}
                      />
                      {/* TODO: Make a ProgressBar component. Same logic found in AddPlace */}
                      {this.state.uploading ? (
                        <div className="upload_images_overlay">
                          <div className="progress_wrapper">
                            <h2>Uploading</h2>
                            {this.state.upload.image ? (
                              <div className="image_name">
                                Image {this.state.upload.image}
                              </div>
                            ) : (
                              ""
                            )}
                            <ProgressBar
                              animated
                              striped
                              variant="success"
                              label={
                                this.state.upload.percentCompleted === 100 &&
                                "Validating results. Please wait!"
                              }
                              now={this.state.upload.percentCompleted}
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <button className="primary_button" onClick={this.addNewPhotos}>Upload</button>
                  </div>
            </ModalComponent>
            }

              {this.state.images.photos.length > 0 ?
                <div>
              <div className="place_photos">
                {/* <img alt="main_place_photo" src={this.state.photo} /> */}
                <AliceCarousel
                  responsive={{
                      0: { items: 1 },
                      767: { items: 3 },
                      1024: { items: 4 },
                  }}
                    startIndex={0}
                    onSlideChanged={this.handleSlideChanged}
                    buttonsDisabled={!buttonsEnabled}
                    mouseDragEnabled
                    dotsDisabled={true}
                    infinite={false}
                    items={this.state.images.photos.map((image, i)=>{return this.drawImage(image, i)})}
                />
              </div>
              <div className="photos_callout">
                Click on photo to zoom in, rotate picture, or see description of picture.
                </div>
              </div>
              :
              <div>
                <div className="pictures_wrapper" style={{width: '100%'}}>
                  <div>
                      <div className="place_image main_image">
                        <span className="helper"></span>
                        <img alt="place_image" className="upload_image" src={this.state.image}
                        style={{width: '100%'}} />

                    </div>
                  </div>
              </div>

              </div>
            }
            {this.props.isLoggedIn ?
              <div className="edit_buttons">

              <div className="upload_button">
              <button onClick={this.openUpload}>Upload Photos</button>
              </div>
              {this.state.isOwner ?
                <div className="edit_button">
                <button className="primary_button" onClick={this.editPlace}>Edit Place</button></div> : ''}

              </div> : ''}

              <div className="place_content">
                <div className="place_left">
                  <div className="place_details place_panel">
                    <div id="name" className="place_details_name">
                      <Score feature_sum={20} accessible={true} /> <h1>{this.state.name}</h1>
                      { /* this.state.official ? <div className="official_review"><i title="Official TravelEZ Review"  alt="Official TravelEZ Review" className="fa fa-star" aria-hidden="true"></i></div>: '' */}
                    </div>
                    <div className="place_details_google">
                      <div className="place_rating">
                        <Rating rating={this.state.rating} />
                        <p>Google Food Rating</p>
                      </div>
                      {price_level ?
                      <div className="place_details_google_price">
                        {this.renderPrice(price_level)}
                      </div> : ''}

                        {/* this.state.cuisine ?
                          <div className="place_details_google_cuisine">
                            <div>Cuisine Type:&nbsp;</div><div className="callout">{this.state.cuisine}</div>
                          </div>  :'' */}
                      <div className="place_details_google_details">
                        <a onClick={this.goToMobileGoogle}>more details</a>
                      </div>
                    </div>
                  </div>
                  <div className="place_accessibility place_panel">
                    <h2>Accessibility Details</h2>
                    <div className="place_features">
                      <div className="place_features_one place_features_column">
                      {this.state.mounted ?
                        <Features
                          showEntranceFeatures={true}
                          hideMissing={true}
                          features={this.state.feature_list}
                          stairs={true}
                          stairs_num={this.state.stairs}
                          deployable={this.state.stairOptions.deployable}
                          entrance={this.state.stairOptions.entrance}
                          lift={this.state.stairOptions.lift}
                        />
                      :''}
                      {/* {
                        this.state.feature_list.filter((v,i) => i < 4).map((v,i)=> { return this.writeAllFeatures(v,i)} )
                      }
                      </div>
                      <div className="place_features_two place_features_column">
                      {
                        this.state.feature_list.filter((v,i) => i >= 4).map((v,i)=> { return this.writeAllFeatures(v,i+4)} )
                      } */}
                      </div>
                    </div>
                  </div>
                  <div className="accessibility_reviews place_panel">
                    <h2>Accessibility Comments & Tips</h2>
                    <button onClick={this.addReviewScroll} className="primary_button add_review_scroll">Edit Place</button>


                    <div className="accessibility_reviews_content">

                      <div className="accessibility_reviews_content_reviews">
                        {this.state.comments.text || this.state.staffRating ?
                              <div className="one_review review_first">
                                <div className="one_review_name_wrapper">
                                  <div className="one_review_name">
                                    {this.state.comments.name}
                                  </div>
                                  {this.state.comments.public ?
                                  <div className="author_details">
                                    <div className="situation">{this.state.comments.situation}</div>
                                    <div className="one_review_date">
                                      {this.state.comments.date}
                                      {this.state.staffRating ?
                                        <div className="place_details_staff_rating">
                                          <div>Staff Rating: </div><Rating staff={true} rating={this.state.staffRating} />
                                        </div>  :''}
                                    </div>
                                  </div>
                                    : ''}
                                </div>
                                <div className="one_review_comment">
                                  {this.state.comments.text}
                                </div>
                              </div>: "" }
                        {this.state.reviews.map(this.writeReview)}
                      </div>
                      <div className="accessibility_reviews_content_add">
                        <h2>Add a Comment</h2>
                        <textarea ref={node => this.review = node} placeholder="Add your accessibility review">
                        </textarea>
                        <button onClick={this.addReview} className="primary_button">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="place_right" id="google_content">
                  <div className="place_details_content place_panel">
                    <div className="place_details_content_social">
                    {this.state.favorite ?
                        <div title="Remove from Favorites" className="place_details_addto_favorite_selected">
                          <FontAwesomeIcon onClick={()=>this.addFavorite(false)} icon="heart"  />
                        </div> :
                        <div title="Add to Favorites" className="place_details_addto_favorite">
                          <FontAwesomeIcon onClick={() => this.addFavorite(true)} icon="heart"  />
                        </div>
                    }
                        <div title="Share" className="place_details_share">
                          <FontAwesomeIcon onClick={this.openShare} icon="share-alt"  />
                        </div>
                    </div>
                    <div className="place_details_google">
                    {this.state.open_time ?
                      <div className="place_details_hours place_details_row">
                        <div className="place_details_icon">
                          <FontAwesomeIcon icon="clock" />
                        </div>
                        <OpeningHours
                          open_now={this.state.open_now}
                          open_time={this.state.open_time}
                          close_time={this.state.close_time}
                          />

                      </div> : "" }
                      <div className="place_details_address place_details_row">
                        <div className="place_details_icon">
                          <FontAwesomeIcon icon="map-marker-alt" />
                        </div>
                        <div id="address" className="place_address"></div>
                      </div>
                      <div className="place_details_phone place_details_row">
                        <div className="place_details_icon">
                          <FontAwesomeIcon icon="phone" />
                        </div>
                        <div>
                          {this.state.phone}
                        </div>
                      </div>
                      <div className="place_details_website place_details_row">
                        <div className="place_details_icon">
                          <FontAwesomeIcon icon="window-maximize" />
                        </div>
                        <div style={{wordWrap: 'break-word', width: 'calc(100% - 30px)'}}>
                          <a target="_blank" href={this.state.website}>{this.state.website}</a>
                        </div>
                      </div>
                      {this.state.mapLink ?
                      <div className="place_details_website place_details_row">
                        <div className="place_details_icon">
                          <FontAwesomeIcon icon="window-maximize" />
                        </div>

                        <div>
                          <a target="_blank" href={this.state.mapLink}>View on Google Maps</a>
                        </div>
                      </div> : ""}
                    </div>
                    <div className="place_map_wrap">
                      <div id='map' />
                    </div>
                    <div className="place_details_row inaccurate_data">
                        <div className="place_details_icon inaccurate_data">
                          <a target="_blank" href="/feedback">Inaccurate data? Let us know!</a>
                        </div>
                    </div>
                    <a
                      target="_blank"
                      href="https://opentable.com"
                      className="place_details_row open_table"
                      onClick={
                        () => window.mixpanel.track("Open Table Clicked", {
                          source: "Place",
                          id: this.state.id,
                          name: this.state.name,
                        })}
                    >
                      <p>Reserve a Table</p>
                      <img alt='open_table' src={OpenTableLogo} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
        );
    }
}
