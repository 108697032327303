import React from "react";
import "./style.css";
import * as logo from "../../assets/logo.png";
import { BaseComponent } from "../../base-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Header extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: false,
      intervalId: 0,
      burger_open: false
    };

    this.toggleBurger = this.toggleBurger.bind(this);
  }

  componentDidMount(props) {
    this.setState({
      isLoggedIn: this.api.utilities.checkToken()
    });
  }

  componentWillReceiveProps(props) {
    this.setState({
      isLoggedIn: this.api.utilities.checkToken()
    });
  }

  goBottom = () => window.scroll(0, window.document.body.scrollHeight);

  toggleBurger() {
    this.setState({
      burger_open: !this.state.burger_open
    });
  }

  render() {
    return (
      <div className="main_header">
        <div className="logo">
          <a href="/">
            <img alt="TravelEZ Logo" src={logo} />
          </a>
        </div>
        <div
          className={
            "links " +
            (this.state.burger_open ? "burger_menu" : "burger_closed")
          }
        >
          <a
            href="/"
            className="red"
            onClick={() =>
              window.mixpanel.track("Nav Bar/Search Clicked", {
                source: "NavBar"
              })
            }
          >
            Search
          </a>
          <a
            href="/addplace"
            className="red"
            onClick={() =>
              window.mixpanel.track("Nav Bar/Add a Place Clicked", {
                source: "NavBar"
              })
            }
          >
            + Add A Place
          </a>
          <a
            target="_blank"
            className="red"
            href="http://travelezblog.org"
            onClick={() =>
              window.mixpanel.track("Nav Bar/Blog Clicked", {
                source: "NavBar"
              })
            }
          >
            Blog
          </a>
          <div className="headerDivider"></div>
          <a
            onClick={() => {
              window.mixpanel.track("Nav Bar/Share Clicked", {
                source: "NavBar"
              });
              this.goBottom();
            }}
          >
            Share
          </a>
          {this.state.isLoggedIn ? (
            <a
              href="/profile"
              onClick={() =>
                window.mixpanel.track("Nav Bar/My Profile Clicked", {
                  source: "NavBar"
                })
              }
            >
              My Profile
            </a>
          ) : (
            <a
              href="/signin"
              onClick={() =>
                window.mixpanel.track("Nav Bar/Sign In/Register Clicked", {
                  source: "NavBar"
                })
              }
            >
              Sign In/Register
            </a>
          )}
        </div>
        <div className="burger">
          <div
            className={
              this.state.burger_open ? "burger_icon close" : "burger_icon"
            }
          >
            <FontAwesomeIcon
              onClick={this.toggleBurger}
              icon={this.state.burger_open ? "times" : "bars"}
            />
          </div>
        </div>
      </div>
    );
  }
}
