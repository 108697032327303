import { PureComponent } from "react";
import { Api } from "./services";

// WTF is this?
export class BaseComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.api = Api;
  }
  checkEnter(functionName, args, e) {
    if (!e) {
      e = args;
      args = [];
    }
    args.unshift(e);
    this[functionName].apply(this, args);
  }
}
