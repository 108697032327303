import { Api } from "../services";

// TODO: Refactor
export default {
  async login(email, password) {
    const response = await Api.utilities.login({ email, password });

    //save token if success
    if (response.success) {
      localStorage.setItem("token", response.token);
      return {
        data: response.data,
        success: true
      };
    } else {
      return {
        success: false,
        error: response.error
      };
    }
  },
  async verify(token) {
    const response = await Api.utilities.verifyToken(token);

    //save token if success
    if (response.success) {
      localStorage.setItem("token", response.token);
      return {
        success: true
      };
    } else {
      return {
        success: false,
        error: response.error
      };
    }
  },
  async forgot(email) {
    const response = await Api.utilities.forgot({ email });

    if (response.success) {
      return {
        success: true
      };
    } else {
      return {
        success: false,
        error: response.error
      };
    }
  },
  async contactUs(email, message) {
    const response = await Api.utilities.contactUs({
      email,
      message,
      type: "contact"
    });

    if (response.success) {
      return {
        success: true
      };
    } else {
      return {
        success: false,
        error: response.error
      };
    }
  },
  async feedback(email, message) {
    const response = await Api.utilities.contactUs({
      email,
      message,
      type: "feedback"
    });

    if (response.success) {
      return {
        success: true
      };
    } else {
      return {
        success: false,
        error: response.error
      };
    }
  },
  async signOut() {
    window.mixpanel.reset();
    localStorage.removeItem("token");
  }
};
