import React from "react";
import { BaseComponent } from "../../base-component";
// import {Rating} from '../Rating';
import "./style.css";
import { OpeningHours } from "../OpeningHours";
import { Score } from "../../components/Score";
import ImagePlaceholder from "../../assets/image_placeholder.jpg";
import AccessibleIcon from "../../assets/accessible.jpg";
import NotAccessibleIcon from "../../assets/not_accessible.jpg";

const stairOptionsMap = {
  deployable: "Deployable Ramp Available",
  entrance: "Side Entrance Available",
  lift: "Lift Available"
};

const isAccessible = (name, features) =>
  !!features.find(feature => feature.name === name);

export class PlaceTile extends BaseComponent {
  renderPrice = priceLevel => (
    <div>
      <span>{"$".repeat(priceLevel)}</span>
      <span className="placeholder">
        {priceLevel < 4 && "$".repeat(4 - priceLevel)}
      </span>
    </div>
  );

  render() {
    const { place, features = [] } = this.props;
    const {
      name,
      image: defaultImage,
      images: rawImages,
      price,
      accessible,
      features: rawPlaceFeatures = [],
      feature_sum,
      stairs,
      stairOptions: rawStairOptions
    } = place;

    // TODO: Create a images db table instead of parsing "images" column every time
    const imagesData = rawImages && JSON.parse(rawImages);
    const imagesByTags =
      imagesData && imagesData.tags
        ? imagesData.tags.reduce((p, c, index) => {
            p[c] = imagesData.photos[index];
            return p;
          }, {})
        : {};

    const entranceImage = imagesByTags[1] || defaultImage;
    const interiorImage = imagesByTags[4];
    const bathroomImage = imagesByTags[2];

    const placeFeatures = rawPlaceFeatures
      ? features.filter(feature => rawPlaceFeatures.indexOf(feature.id) !== -1)
      : [];

    // TODO: Do not use hardcoded values
    const isEntryAccessible =
      isAccessible("Smooth Entry Surface", placeFeatures) ||
      isAccessible("Wheelchair Accessible Entry", placeFeatures);

    const isInteriorAccessible = isAccessible(
      "Accessible Tables",
      placeFeatures
    );
    const isBathroomAccessible = isAccessible(
      "Accessible Bathroom",
      placeFeatures
    );

    const stairOptions = rawStairOptions && JSON.parse(rawStairOptions);
    const formattedStairOptions = (stairOptions
      ? Object.keys(stairOptions).reduce((p, key) => {
          const isTrue = stairOptions[key];
          if (isTrue) {
            p.push(stairOptionsMap[key]);
          }

          return p;
        }, [])
      : []
    ).join(", ");
    const formattedNumberOfStairs = stairs && `${stairs} Stairs`;

    const tileColor = (isEntryAccessible || accessible) ? "green" : "red";

    return (
      <a
        target="_blank"
        className="place_tile_link"
        href={"/place/" + this.props.place.id}
        onClick={() =>
          window.mixpanel.track("Place Tile Clicked", {
            source: this.props.source
          })
        }
      >
        <div id={"place_tile_" + this.props.index} className="place_tile">
          <div className={"place_name " + tileColor}>
            <div className="place_name_score">
              <Score
                accessible={isEntryAccessible || accessible}
                feature_sum={feature_sum}
              />
            </div>
            <div className="place_name_name">
              <a
                target="_blank"
                href={"/place/" + this.props.place.id}
                onClick={() =>
                  window.mixpanel.track("Place Tile Clicked", {
                    source: this.props.source
                  })
                }
              >
                {name}
              </a>
            </div>
          </div>
          <div className="place_accessability_details">
            <div className="place_accessability">
              <span>Entry</span>
              <div className="photo_container">
                <img
                  className="place_photo"
                  alt={name}
                  src={entranceImage || ImagePlaceholder}
                />
                <img
                  className="accessability_rating"
                  alt={name}
                  src={isEntryAccessible ? AccessibleIcon : NotAccessibleIcon}
                />
              </div>
            </div>
            <div className="secondary_accessabilities">
              <div className="place_accessability">
                <span>Interior</span>
                <div className="photo_container">
                  <img
                    className="place_photo"
                    alt={name}
                    src={interiorImage || ImagePlaceholder}
                  />
                  <img
                    className="accessability_rating"
                    alt={name}
                    src={
                      isInteriorAccessible ? AccessibleIcon : NotAccessibleIcon
                    }
                  />
                </div>
              </div>
              <div className="place_accessability">
                <span>Bathroom</span>
                <div className="photo_container">
                  <img
                    className="place_photo"
                    alt={name}
                    src={bathroomImage || ImagePlaceholder}
                  />
                  <img
                    className="accessability_rating"
                    alt={this.props.place.name}
                    src={
                      isBathroomAccessible ? AccessibleIcon : NotAccessibleIcon
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div>
            <div className="place_details">
              <div className="price_detail">{this.renderPrice(price)}</div>
              <OpeningHours
                open_now={this.props.place.open_now}
                open_time={this.props.place.open_time}
                close_time={this.props.place.close_time}
              />
              {!isEntryAccessible &&
                (stairs || !!formattedStairOptions.length) && (
                  <div className="accessability_notes">
                    {[formattedNumberOfStairs, formattedStairOptions]
                      .filter(Boolean)
                      .join(", ")}
                  </div>
                )}
            </div>
          </div>
        </div>
      </a>
    );
  }
}
