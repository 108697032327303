import React from "react";
import { BaseComponent } from "../../base-component";
import NoAuthController from "../../controllers/noAuthController";
import Utilities from "../../utilities";
import "./style.css";

export default class ForgotPassword extends BaseComponent {
  state = {
    message: "",
    emailSent: false,
    errors: {
      e: false
    }
  };

  forgot = async event => {
    window.mixpanel.track("Send Forgotten Password Clicked", {
      source: "ForgotPassword"
    });

    event.preventDefault();

    let email = this.email.value;

    let valid = true;
    let message = "";

    let errors = {
      e: !email
    };

    if (!email) {
      valid = false;
      message = "Email is required.";
    }

    if (valid && !Utilities.validateEmail(email)) {
      valid = false;
      message = "Email is not in the appropriate format";
      errors.e = true;
    }
    this.setState({ errors });

    if (valid) {
      const response = await NoAuthController.forgot(email);

      if (response.success) {
        this.setState({
          emailSent: true
        });
      } else {
        this.setState({
          message: response.error
        });
      }
    } else {
      this.setState({
        message
      });
    }
  };

  render() {
    return (
      <div className="forgot_main">
        <div className="forgot_header">
          <h2>Forgot your password?</h2>
        </div>
        {!this.state.emailSent ? (
          <div>
            <div className="forgot_content">
              Enter your email address below and we'll send you a temporary
              password. <br />
              Don't forget to change your password after loggin in to the site.
            </div>
            <input
              className={this.state.errors.e ? "input_error" : "input_normal"}
              ref={email => (this.email = email)}
              type="email"
              placeholder="email"
            />
            {this.state.message ? (
              <div className="forgot_message">{this.state.message}</div>
            ) : (
              ""
            )}
            <button onClick={this.forgot}>Send</button>
          </div>
        ) : (
          <div className="forgot_content">
            We've sent you an email with the temporary password.
          </div>
        )}
      </div>
    );
  }
}
