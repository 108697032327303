import React from "react";
import { get } from "lodash";
import { BaseComponent } from "../../base-component";
import NoAuthController from "../../controllers/noAuthController";
import Utilities from "../../utilities";
import Form from '../../components/SignInForm';
import "./style.css";

export default class SignIn extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
      errors: {
        e: false,
        p: false
      },
      prevPath: get(props, "location.state.path")
    };
  }

  handleSignInClick = async (email, password) => {
    window.mixpanel.track("Sign In Clicked", { source: "SignIn", email });
    
    let message = "";
    
    // TODO: Refactor
    let errors = {
      e: !email,
      p: !password
    };

    let valid = true;
    if (!email || !password) {
      valid = false;
      message = "All fields are required";
    }

    if (valid && !Utilities.validateEmail(email)) {
      valid = false;
      message = "Email is not in the appropriate format";
      errors.e = true;
    }

    this.setState({ errors });

    if (valid) {
      const response = await NoAuthController.login(email, password);
      response.success &&
        window.mixpanel.identify(get(response, "data.userId"));

      window.mixpanel.track("Signed In", { source: "SignIn" });

      if (response.success) {
        this.props.onSignIn(() => {
          if (this.state.prevPath) {
            this.props.history.push("/" + this.state.prevPath);
          } else {
            this.props.history.push("/profile");
          }
        });
      } else {
        this.setState({
          message: response.error
        });
      }
    } else {
      this.setState({
        message
      });
    }
  };

  handleRegisterClick = () => {
    window.mixpanel.track("Register Clicked", { source: "SignIn" });

    this.props.history.push("/register");
  };

  render() {
    const {errors, message} = this.state;

    return (
      <div className="signin_main">
        <div className="signin_header">
          <h2>Sign In</h2>
        </div>
        <Form 
          message={message}
          errors={errors}
          onSignInClick={this.handleSignInClick}
          onRegisterClick={this.handleRegisterClick}
        />
      </div>
    );
  }
}
