import axios from 'axios';
import { Config } from '../../config';
import * as uuid from 'uuid';

export class BaseApiService {
    constructor() {
        this.initInstance();
        this.currentRaces = [];
    }
    setToken(token) {
        if (token !== this.token && token) {
            this.token = token;
            this.initInstance();
        }
    }
    getHeaders() {
        let headers = {
            'Content-Type': 'application/json',
            "cache-control": "no-cache"
        };
        if (this.token) {
            headers['Authorization'] = `${this.token}`;
        }
        return headers;
    }
    initInstance() {
        let options = {
            baseURL: Config.apiURL,
            timeout: 6000 * 1000,
            headers: this.getHeaders()
        };
        this.instance = axios.create(options);
    }
    handleResponse(res) {
        console.log("response handle", res);
        return res.data;
    }
    handleError(res) {
        console.log("error handle", res);
        return res;
    }
    withPreventingRaceCondition(url, func) {
        let race = {
            url: url,
            id: uuid.v4(),
            time: Date.now(),
        };
        this.currentRaces = this.currentRaces.filter(c_race => {
            return c_race.url !== race.url;
        });
        this.currentRaces.push(race);
        return new Promise((resolve, reject) => {
            func().then(response => {
                if (this.currentRaces.find(c_race => c_race.id === race.id)) {
                    resolve(response);
                }
            }, reject);
        });
    }
    doGet(url, params, fromCache, storage, key) {
        let doRequest = () => {
            return this.instance({
                method: 'GET',
                url: url,
                params: params,
            }).then(this.handleResponse, this.handleError);
        };
        /* //storage memory, session, local
        if (fromCache && storage && key) {
            return this.storage.get(key, storage).then(data => {
                if (data) {
                    return data;
                } else {
                    return doRequest().then(response => {
                        this.storage.add(key, response, storage);
                        return response;
                    });
                }
            });
        } */
        return doRequest();
    }

    doOutsideGet(url) {
        return axios.get(url).then(this.handleResponse, this.handleError);
    }

    doPost(url, data, params = {}) {
        console.log(url, data);
        return this.instance({
            url: url,
            method: 'POST',
            params: params,
            data: data,
        }).then(this.handleResponse, this.handleError);
    }
    getFormHeaders() {
        let headers = {
            'Content-Type': 'multipart/form-data',
            "Cache-Control": "no-cache"
        };
        if (this.token) {
            headers['Authorization'] = `${this.token}`;
        }
        return headers;
    }
    doPostFormData(url, formData, config = {}) {
        let formOptions = config;
        formOptions.baseURL = Config.apiURL;
        formOptions.timeout = 240 * 1000;
        formOptions.headers = this.getFormHeaders();

        return axios.post(url, formData, formOptions).then(this.handleResponse, this.handleError);
    }
    doDelete(url, data, params = {}) {
        return this.instance({
            url: url,
            method: 'DELETE',
            params: params,
            data: data,
        }).then(this.handleResponse, this.handleError);
    }
    doPut(url, data, params = {}) {
        return this.instance({
            url: url,
            method: 'PUT',
            params: params,
            data: data,
        }).then(this.handleResponse, this.handleError);
    }
    doPatch(url, data, params = {}) {
        return this.instance({
            url: url,
            method: 'PATCH',
            params: params,
            data: data,
        }).then(this.handleResponse, this.handleError);
    }
}
