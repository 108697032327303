import React from "react";
import { BaseComponent } from "../../base-component";
import NoAuthController from "../../controllers/noAuthController";

export default class Verify extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      token: props.match.params.token
    };
  }

  async componentDidMount(newProps) {
    if (!this.state.token) {
      this.setState({ message: "Token is not valid" });
    } else {
      const response = await NoAuthController.verify(this.state.token);
      if (response.success) {
        this.props.onVerify(() => {
          this.props.history.push("/profile");
        });
      } else {
        this.setState({
          message: "Token is not valid"
        });
      }
    }
  }

  render() {
    return (
      <div>
        <h1>{this.state.message}</h1>
      </div>
    );
  }
}
