import React from "react";
import { BaseComponent } from "../../base-component";
import Utilities from "../../utilities";
import "./style.css";

export default class Register extends BaseComponent {
  constructor() {
    super();

    this.state = {
      modalIsOpen: false,
      message: "",
      isRegistered: false,
      errors: {
        e: false,
        p: false,
        c: false
      },
      situation: null,
      city: null,
      survey: null,
    };

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.register = this.register.bind(this);
    this.selectSituation = this.selectSituation.bind(this);
  }

  componentDidMount(newProps) {
    console.log(newProps);
    console.log(this.props);
    this.setState({
      modalIsOpen: this.props.modalIsOpen,
      thankYouIsOpen: false
    });
  }

  componentWillReceiveProps(newProps) {
    console.log(newProps);
    if (newProps.modalIsOpen !== this.state.modalIsOpen) {
      this.setState({
        modalIsOpen: newProps.modalIsOpen
      });
    }
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = "#f00";
  }

  async register() {
    // TODO: Use state as this is NOT the react way of doing things
    const email = this.email.value;
    const cemail = this.cemail.value;
    const password = this.password.value;
    const cpassword = this.cpassword.value;
    const publicOn = this.public.checked;
    const {situation, city, survey} = this.state;

    localStorage.setItem("city", city);

    window.mixpanel.track("Register Clicked", { email, source: "Register" });

    let errors = {
      e: !email,
      p: !password,
      c: !cpassword
    };

    let valid = true;
    let message = "";

    if (!email || !password || !cpassword) {
      valid = false;
      message = "All fields are required";
    }

    if (valid && cemail !== email) {
      valid = false;
      message = "Emails do not match";
      errors.e = true;
    }

    if (valid && cpassword !== password) {
      valid = false;
      message = "Passwords do not match";
      errors.p = true;
      errors.c = true;
    }

    if (valid && !Utilities.validateEmail(email)) {
      valid = false;
      message = "Email is not in the appropriate format";
      errors.e = true;
    }

    this.setState({ errors });

    if (valid) {
      let response = await this.api.utilities.register({
        email,
        password,
        publicOn: publicOn,
        situation,
        city,
        survey
      });

      if (response.success) {
        window.mixpanel.track("Registered", { source: "Register" });

        const {token} = response;
        localStorage.setItem("token", token);

        this.props.history.push("/");

        // Note: Disable confirm email logic for now
        // this.setState({
        //   thankYouIsOpen: true,
        //   modalIsOpen: false,
        //   isRegistered: true
        // });
      } else {
        this.setState({
          message: response.error
        });
      }
    } else {
      this.setState({
        message
      });
    }
  }

  selectSituation(event) {
    let value = event.target.value;
    this.setState({
      situation: value !== -1 ? value : null
    });
  }

  handleSelectCity = e => {
    const {value} = e.target;
    const city = value !== -1 ? value : null;

    this.setState({city});
  }

  handleSelectSurvey = e => {
    const {value} = e.target;
    this.setState({survey: value})
  }

  render() {
    const {city} = this.state;
    
    return (
      <div className="register_main">
        {!this.state.isRegistered ? (
          <div>
            <div className="register_header">
              <h2>Register</h2>
            </div>
            <input
              className={this.state.errors.e ? "input_error" : "input_normal"}
              ref={email => (this.email = email)}
              type="email"
              placeholder="email"
              autoComplete="new-password"
              onCopy={e => {
                e.preventDefault();
                e.nativeEvent.stopImmediatePropagation();
              }}
            />
            <input
              className={this.state.errors.e ? "input_error" : "input_normal"}
              ref={cemail => (this.cemail = cemail)}
              type="email"
              placeholder="confirm email"
              onPaste={e => {
                e.preventDefault();
                e.nativeEvent.stopImmediatePropagation();
              }}
              autoComplete="new-password"
            />
            <input
              className={this.state.errors.p ? "input_error" : "input_normal"}
              ref={password => (this.password = password)}
              type="password"
              placeholder="password"
              autoComplete="new-password"
            />
            <input
              className={this.state.errors.c ? "input_error" : "input_normal"}
              ref={cpassword => (this.cpassword = cpassword)}
              type="password"
              placeholder="confirm password"
              autoComplete="new-password"
            />
            <div>Select Your City</div>
              <select
                value={city}
                onChange={this.handleSelectCity}
              >
                <option value={-1}>Select city...</option>
                <option value="New York">
                  New York
                </option>
                <option value="Boston">
                  Boston
                </option>
            </select>
            <div>Tell us a little about your needs:</div>
            <select onChange={this.selectSituation}>
              <option value={-1}>Select One...</option>
              <option value="I use a manual wheelchair user">
                I use a manual wheelchair
              </option>
              <option value="I use a power wheelchair user">
                I use a power wheelchair
              </option>
              <option value="I use a scooter">I use a scooter</option>
              <option value="I use an assistive device besides wheelchair">
                I use an assistive device besides wheelchair/scooter
              </option>
              <option value="I am a slow walker">I am a slow walker</option>
              <option value="I use a stroller">I use a stroller</option>
              <option value="I am a caretaker">I am a caretaker</option>
              <option value="Other">Other</option>
              <option value="Prefer not to say ">Prefer not to say</option>
            </select>
            <div>How did you hear about us?</div>
            <select onChange={this.handleSelectSurvey}>
              <option value={-1}>Select One...</option>
              <option value="Through a friend">Through a friend</option>
              <option value="Through a TravelEZ ambassador">Through a TravelEZ ambassador</option>
              <option value="Social Media">Social Media</option>
              <option value="Google Search">Google Search</option>
              <option value="United Spinal Association">United Spinal Association</option>
              <option value="Mt. Sinai">Mt. Sinai</option>
              <option value="Other">Other</option>
            </select>
            <div className="public_text">
              Do you want your accessibility needs to be public? Making public
              adds context to your posts to help other users make better
              decisions. We recommend keeping public!
            </div>            
            <div className="public_check_wrapper">
              <input
                id="privateCheck"
                type="checkbox"
                defaultChecked={true}
                ref={node => (this.public = node)}
              />
              <label className="public_label" for="privateCheck">
                Public
              </label>
            </div>
            {this.state.message ? (
              <div className="register_message">{this.state.message}</div>
            ) : (
              ""
            )}
            <button onClick={this.register}>Register</button>

            <div className="register_callout">
              Already have an account?{" "}
              <a className="on_teal_link" href="/signin">
                Sign In
              </a>
            </div>
          </div>
        ) : (
          <div>
            <div className="register_header">
              <h2>
                Thank you for Registering! <br />
                We have sent you an email to verify your email address.
              </h2>
            </div>
          </div>
        )}
      </div>
    );
  }
}
