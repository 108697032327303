import { UtilitiesService } from './utilities';
import { PlacesService } from './places';

class ApiService {
    constructor() {
        this.utilities = new UtilitiesService();
        this.places = new PlacesService();
    }
}

export const Api = new ApiService();
