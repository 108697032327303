import React from "react";
import { BaseComponent } from "../../base-component";
import "./style.css";

import RampIcon from "../../assets/ramp.png";
import NotSelectedRampIcon from "../../assets/ramp-not.png";
import EntranceIcon from "../../assets/entrance-staff.png";
import NotSelectedEntranceIcon from "../../assets/entrance-staff-not.png";
import LiftIcon from "../../assets/lift.png";
import NotSelectedLiftIcon from "../../assets/lift-not.png";

/**
 * 2pt​ Smooth ground service
    4pt​ Step free access
    1pt​ Designated accessible parking
    1pt​ Drop off area

    4pt​ Accessible table and seats
    3pt​ Spacious interior
    2pt​ Step free layout
    2pt​ Do not need stairs to access other floors
    3pt​ Accessible restrooms

    Outcome
    Easy: 19 points or more
    Medium: 15, 16, 17, or 18 points
    Not Recommended: 14 or fewer points
 */

// TODO: Refactor
export class Features extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      features: []
    };
  }

  componentDidMount() {
    if (this.props.features) {
      console.log("this.props", this.props);
      // TODO:
      this.setState({
        features: this.props.features
      });
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.features) {
      this.setState({
        features: newProps.features
      });
    }
  }

  renderFeature = (feature, index, side) => {
    const { showCheckBox } = this.props;

    return (
      <div className={"feature_choice_wrapper " + side}>
        <div
          className="feature_choice"
          style={{ width: this.props.stairs ? "100%" : "" }}
        >
          <div
            className="feature_main"
            onClick={event => {
              this.props.toggleFeature(event, index);
            }}
          >
            <div className="radio_choices">
              {!feature.checked ? (
                <img
                  className="feature_image"
                  alt="icon"
                  src={feature.iconNot}
                />
              ) : (
                <img className="feature_image" alt="icon" src={feature.icon} />
              )}
              {showCheckBox ? (
                <input
                  type="checkbox"
                  checked={feature.checked}
                  onChange={() => null}
                />
              ) : (
                ""
              )}
            </div>
            <div className="feature_name">
              <label for={index}>{feature.name}</label>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    );
  };

  render() {
    const {
      stairs,
      stairs_num,
      deployable,
      entrance,
      lift,
      showCheckBox,
      showEntranceFeatures,
      hideMissing
    } = this.props;

    // TODO: Revise showEntranceFeatures logic
    const shouldShowEntranceFeatures = showEntranceFeatures && stairs_num > 0;

    // TODO: Make each of these a component
    return (
      <div>
        <div className="main_features">
          <div className="features_row">
            {this.state.features
              .filter((value, index) => index < 3)
              .map((feature, index) =>
                this.renderFeature(feature, index, "left")
              )}
            {this.state.features
              .filter((value, index) => index >= 3)
              .map((feature, index) =>
                this.renderFeature(feature, index + 3, "right")
              )}
          </div>
          <div className="accessibility_description">
            &#9432;{" "}
            <a
              target="_blank"
              href="https://travelezblog.org/blog/accessibility-details-explained"
              onClick={() =>
                window.mixpanel.track(
                  "Click here for a description of Accessibility Features",
                  { source: "Features" }
                )
              }
            >
              Click here for a description of Accessibility Features
            </a>
          </div>
        </div>
        {shouldShowEntranceFeatures && (
          <div className="features_row secondary_features">
            {hideMissing && !stairs ? null : (
              <div className="feature stairs">
                {showCheckBox && <div style={{ width: 40 }} />}
                <img
                  width="60"
                  alt="stairs not accessible"
                  src={
                    "https://travelez.s3.amazonaws.com/uploads/stairs_not.svg"
                  }
                />
                <div className="number_stairs">
                  {/* HACK: Need this because of the css's overlapping */}
                  <div className="feature_name">Number of Stairs: </div>
                  <div className="number_buttons">
                    {showCheckBox ? (
                      <div
                        className="circle_button"
                        onClick={this.props.decreaseStairs}
                      >
                        -
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="stairs_number">
                      {stairs_num}
                      {stairs_num === 5 ? "+" : ""}
                    </div>
                    {showCheckBox ? (
                      <div
                        onClick={this.props.increaseStairs}
                        className="circle_button"
                      >
                        +
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            )}

            {hideMissing && !deployable ? null : (
              <div className="feature">
                <div
                  className="feature_main"
                  onClick={() => {
                    if (showCheckBox) {
                      this.props.toggleStairs("deployable");
                    } else {
                      return;
                    }
                  }}
                  style={{ width: "100%" }}
                >
                  <div className="radio_choices">
                    <img
                      onClick={() => {
                        if (showCheckBox) {
                          this.props.toggleStairs("deployable");
                        } else {
                          return;
                        }
                      }}
                      width="60"
                      alt="deployable ramp"
                      src={deployable ? RampIcon : NotSelectedRampIcon}
                    />
                    {!hideMissing && (
                      <input type="checkbox" checked={deployable} />
                    )}
                  </div>
                  <div className="feature_name">
                    <label for="deployable">Deployable Ramp</label>
                  </div>
                </div>
              </div>
            )}

            {hideMissing && !entrance ? null : (
              <div className="feature">
                <div
                  onClick={event => {
                    if (showCheckBox) {
                      this.props.toggleStairs("entrance");
                    } else {
                      return;
                    }
                  }}
                  className="feature_main"
                  style={{ width: "100%" }}
                >
                  <div className="radio_choices">
                    <img
                      onClick={event => {
                        if (showCheckBox) {
                          this.props.toggleStairs("entrance");
                        } else {
                          return;
                        }
                      }}
                      width="60"
                      alt="staff operated entrance"
                      src={entrance ? EntranceIcon : NotSelectedEntranceIcon}
                    />
                    {!hideMissing && (
                      <input type="checkbox" checked={entrance} />
                    )}
                  </div>
                  <div className="feature_name">
                    <label for="entrance">Staff-Operated Side Entrance</label>
                  </div>
                </div>
              </div>
            )}
            {hideMissing && !lift ? null : (
              <div className="feature">
                <div
                  className="feature_main"
                  onClick={event => {
                    if (showCheckBox) {
                      this.props.toggleStairs("lift");
                    } else {
                      return;
                    }
                  }}
                  style={{ width: "100%" }}
                >
                  <div className="radio_choices">
                    <img
                      onClick={event => {
                        if (showCheckBox) {
                          this.props.toggleStairs("lift");
                        } else {
                          return;
                        }
                      }}
                      width="60"
                      alt="lift"
                      src={lift ? LiftIcon : NotSelectedLiftIcon}
                    />
                    {!hideMissing && <input type="checkbox" checked={lift} />}
                  </div>
                  <div className="feature_name">
                    <label for="lift">Lift</label>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
