import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cloneDeep } from "lodash";
import React from "react";
import { BaseComponent } from "../../base-component";
import { PlacesController } from "../../controllers/placesController";
import ImageUploader from "react-images-upload";
import AliceCarousel from "react-alice-carousel";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./style.css";
import Autocomplete from "react-google-autocomplete";
import { Features } from "../../components/Features";
import { Rating } from "../../components/Rating";
import ModalComponent from "../../components/Modal";

// TODO: Refactor
export default class AddPlace extends BaseComponent {
  constructor(props) {
    super(props);

    let placeId = this.props.match.params.id;

    this.state = {
      message: "",
      step: {
        step: placeId ? 2 : 1,
        name: "secondary_panel"
      },
      features: [],
      mounted: false,
      pictures: [],
      picture_tags: [],
      descriptions: [],
      files: [],
      name: "",
      comments: "",
      googlePlace: null,
      upload: {
        percentCompleted: 0,
        image: 1
      },
      uploading: false,
      stairs: 1,
      staffRating: false,
      rating: 20,
      placeId,
      stairOptions: {
        deployable: false,
        entrance: false,
        lift: false
      },
      isFavorite: false,
      isShareOpen: false
    };

    this.controller = new PlacesController();

    this.toggleFeature = this.toggleFeature.bind(this);
    this.setGooglePlace = this.setGooglePlace.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.increaseStairs = this.increaseStairs.bind(this);
    this.decreaseStairs = this.decreaseStairs.bind(this);
    this.updateTags = this.updateTags.bind(this);
    this.selectStaffRating = this.selectStaffRating.bind(this);
    this.updateStaffRating = this.updateStaffRating.bind(this);
    this.drawImage = this.drawImage.bind(this);
    this.removePhoto = this.removePhoto.bind(this);
  }

  async componentDidMount() {
    let features = await this.controller.getFeatures();

    if (this.state.placeId) {
      let date = {
        time: new Date().toLocaleTimeString(),
        day: new Date().getDay()
      };
      let res = await this.controller.getPlace(
        this.state.placeId,
        JSON.stringify(date)
      );
      let place = res.place[0];

      let selected_features = this.getSelectedFeatures(
        JSON.parse(place.features),
        features.features
      );
      let images = JSON.parse(place.images);

      let photos = images && images.photos ? images.photos : [];
      let tags = images && images.tags ? images.tags : [];
      let descriptions =
        images && images.descriptions ? images.descriptions : [];

      if (descriptions.length === 0) {
        for (var i = 0; i < images.length; i++) {
          descriptions.push("");
        }
      }

      if (tags.length === 0) {
        for (var i = 0; i < images.length; i++) {
          tags.push("");
        }
      }

      let stairOptions = JSON.parse(place.stairOptions);
      this.setState({
        features: selected_features,
        comments: place.comments,
        images: photos,
        pictures: photos,
        picture_tags: tags,
        descriptions,
        name: place.name,
        rating: parseInt(place.staffrating, 0),
        staffRating: place.staffrating ? true : this.state.staffRating,
        mounted: true,
        stairs: place.stairs,
        stairOptions: stairOptions ? stairOptions : this.state.stairOptions
      });
    } else {
      this.setState({ features: features.features, mounted: true }, () => {
        this.autocomplete.autocomplete.setFields([
          "formatted_address",
          "formatted_phone_number",
          "addressComponents",
          "opening_hours",
          "name",
          "photos",
          "placeId",
          "price_level",
          "rating",
          "website",
          "geometry",
          "url"
        ]);
      });
    }
  }

  getSelectedFeatures(features, allFeatures) {
    let selected_features = [];
    for (var i = 0; i < allFeatures.length; i++) {
      if (features.indexOf(allFeatures[i].id) !== -1) {
        selected_features.push({
          id: allFeatures[i].id,
          checked: true,
          points: allFeatures[i].points,
          name: allFeatures[i].name,
          order: allFeatures[i].order,
          iconNot: allFeatures[i].iconNot,
          icon: allFeatures[i].icon
        });
      } else {
        selected_features.push({
          id: allFeatures[i].id,
          checked: false,
          points: allFeatures[i].points,
          name: allFeatures[i].name,
          order: allFeatures[i].order,
          iconNot: allFeatures[i].iconNot,
          icon: allFeatures[i].icon
        });
      }
    }
    return selected_features;
  }

  componentDidUpdate() {
    if (!this.state.placeId) {
      this.autocomplete.autocomplete.setFields([
        "formatted_address",
        "formatted_phone_number",
        "addressComponents",
        "opening_hours",
        "name",
        "photos",
        "placeId",
        "price_level",
        "rating",
        "website",
        "geometry",
        "url"
      ]);
    }
  }

  increaseStairs() {
    this.setState({
      stairs: this.state.stairs > 4 ? 5 : this.state.stairs + 1
    });
  }

  decreaseStairs() {
    this.setState({
      stairs: this.state.stairs === 1 ? 1 : this.state.stairs - 1
    });
  }

  submitReview = async event => {
    await this.props.checkToken(() => {});

    if (this.props.isLoggedIn) {
      event.preventDefault();

      this.setState({ uploading: true });

      const comments = this.comments.value;

      // TODO: Validate?
      let valid = true;
      let message = "";

      if (valid) {
        const features = this.getFeatures(this.state.features);

        const that = this;

        const config = {
          onUploadProgress: function(progressEvent) {
            that.setState({
              upload: {
                percentCompleted: Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                )
              }
            });
          }
        };

        // Upload photos first
        const photos = this.state.images || [];

        // Refactor this
        for (let i = 0; i < this.state.files.length; i++) {
          const formData = new FormData();
          formData.append("file", this.state.files[i]);

          this.setState({
            upload: {
              ...this.state,
              image: i + 1
            }
          });

          // TODO: Handle failed to upload images
          const photoUrl = await this.controller.uploadPhotos(formData, config);
          photoUrl && photos.push(photoUrl.data.Location);
        }

        const staffRating = this.state.staffRating ? this.state.rating : null;

        let gPlace = null;

        if (!this.state.placeId) {
          gPlace = {
            formatted_phone_number: this.state.googlePlace
              .formatted_phone_number
              ? this.state.googlePlace.formatted_phone_number
              : "",
            addressComponents: this.state.googlePlace.addressComponents,
            opening_hours: this.state.googlePlace.opening_hours,
            name: this.state.googlePlace.name,
            photos: this.state.googlePlace.photos,
            placeId: this.state.googlePlace.placeId,
            price_level: this.state.googlePlace.price_level,
            rating: this.state.googlePlace.rating,
            website: this.state.googlePlace.website,
            geometry: this.state.googlePlace.geometry,
            url: this.state.googlePlace.url
          };
        }

        let data = {
          comments,
          features,
          gPhoto: this.state.photoUrl,
          gPlace,
          lat: this.state.googlePlace
            ? this.state.googlePlace.geometry.location.lat()
            : null,
          lng: this.state.googlePlace
            ? this.state.googlePlace.geometry.location.lng()
            : null,
          images: JSON.stringify({
            photos,
            tags: this.state.picture_tags,
            descriptions: this.state.descriptions
          }),
          stairs: this.state.stairs,
          stairOptions: this.state.stairOptions,
          staffRating,
          placeId: this.state.placeId
        };

        let response = await this.controller.addPlace(data);

        // TODO: Refactor this
        if (response.success) {
          this.setState(
            {
              placeLinkId: response.data.id,
              uploading: false
            },
            () => {
              this.goNext(5);
            }
          );
        } else {
          this.setState({
            uploading: false,
            message: response.error
          });
        }
      } else {
        this.setState({
          uploading: false,
          message
        });
      }
    } else {
      this.props.history.push("/signin");
    }
  };

  getFeatures(features) {
    let newFeatures = [];
    for (var i = 0; i < features.length; i++) {
      if (features[i].checked) {
        newFeatures.push({ id: features[i].id, points: features[i].points });
      }
    }
    return JSON.stringify({ features: newFeatures });
  }

  removePhoto(index) {
    let photos = cloneDeep(this.state.pictures);
    let tags = cloneDeep(this.state.picture_tags);
    let descriptions = cloneDeep(this.state.descriptions);
    let files = cloneDeep(this.state.files);

    photos.splice(index, 1);
    tags.splice(index, 1);
    descriptions.splice(index, 1);
    files.splice(index, 1);

    this.setState({
      pictures: photos,
      picture_tags: tags,
      descriptions,
      files
    });
  }

  goNext = async step => {
    await this.props.checkToken(() => {});
    if (this.props.isLoggedIn) {
      if (this.state.googlePlace || this.state.placeId) {
        let stepCurrent = this.state.step;

        //let name = "secondary_panel";

        /* switch(step) {
          case 1:
          name = "primary_panel";
            break;
          case 2:
          name = "secondary_panel";
            break;
          case 3:
          name = "tertiary_panel";
            break;
          case 4:
          name = "secondary_panel"
            break;
          default:
          name = "tertiary_panel";
            break;
        } */

        stepCurrent = {
          ...this.state.step,
          step
        };

        console.log("stepCurrent", stepCurrent);

        this.setState({
          step: stepCurrent
        });

        document.body.scrollTop = document.documentElement.scrollTop = 0;
      }
    } else {
      this.props.history.push("/signin");
    }
  };

  toggleFeature(event, index) {
    let features = this.state.features.slice(0);

    let toggleVal = !features[index].checked;

    features[index].checked = toggleVal;

    let stairs = this.state.stairs;
    if (index === 0) {
      if (toggleVal) stairs = 0;
      else stairs = 1;
    }

    this.setState({
      features: features,
      stairs
    });
  }

  goToPlace = () =>
    this.props.history.push(
      "/place/" + (this.state.placeId || this.state.placeLinkId)
    );

  goHome = () => this.props.history.push("/");

  onDrop(files) {
    const that = this;

    var reader = new FileReader();
    function readFile(index) {
      if (index >= files.length) {
        that.uploader.state.files = [];
        that.uploader.state.pictures = [];
        return;
      }
      var file = files[index];
      reader.onload = function(e) {
        // get file content
        var bin = e.target.result;

        let pictures = that.state.pictures.slice(0);
        pictures.push(bin);

        let picture_tags = that.state.picture_tags
          ? that.state.picture_tags.slice(0)
          : [];
        // picture_tags.push("0");
        let descriptions = that.state.descriptions
          ? cloneDeep(that.state.descriptions)
          : [];
        descriptions.push("");

        that.setState(
          {
            pictures: pictures,
            files: files,
            picture_tags,
            descriptions
          },
          () => {
            // do sth with bin
            readFile(index + 1);
          }
        );
      };
      reader.readAsDataURL(file);
    }
    readFile(0);
  }

  updateTags(event, index) {
    let picture_tags = this.state.picture_tags.slice(0);
    picture_tags[index] = event.target.value;
    this.setState({
      picture_tags
    });
  }

  // updateDescriptions(event, index) {
  //   let descriptions = cloneDeep(this.state.descriptions);
  //   descriptions[index] = event.target.value;
  //   this.setState({
  //     descriptions
  //   });
  // }

  drawImage(picture, index) {
    return (
      <div className="pictures_wrapper">
        <div>
          <div className="place_image" key={index + "place"}>
            <span className="helper"></span>
            <img alt="place_image" className="upload_image" src={picture} />
          </div>
        </div>
        <div className="picture_tags">
          <select
            value={this.state.picture_tags[index]}
            onChange={event => this.updateTags(event, index)}
          >
            <option value="" disabled selected>
              Tag
            </option>
            <option value={0}>Other</option>
            <option value={1}>Entrance</option>
            <option value={2}>Bathroom</option>
            <option value={3}>Tables/Chairs</option>
            <option value={4}>Overview of Space</option>
          </select>
        </div>
        <div>
          {/* <textarea
            placeholder="Add a description"
            value={this.state.descriptions[index]}
            className="image_description"
            onChange={event => this.updateDescriptions(event, index)}
            maxLength="100"
          ></textarea> */}
          <button
            onClick={() => {
              this.removePhoto(index);
            }}
          >
            Remove
          </button>
        </div>
      </div>
    );
  }

  async setGooglePlace(place) {
    // Check if place already exists
    let response = await this.controller.checkPlace(place.placeId);

    if (response.success) {
      if (response.exists) {
        this.setState({
          googlePlace: null,
          exists: {
            place: response.place,
            name: place.name
          }
        });
      } else {
        let photoUrl;
        if (place.photos.length > 0) {
          photoUrl = place.photos[0].getUrl({ maxWidth: 500, maxHeight: 500 });
        }

        this.setState({
          googlePlace: place,
          exists: null,
          photoUrl
        });
      }
    } else {
      this.setState({
        message: response.error
      });
    }
  }

  writeProgress(num) {
    return (
      <div className="addplace_progress">
        <div className="addplace_progress_bar">
          <div
            className={
              "addplace_progress_step" +
              (num > 1 ? " complete" : "") +
              (num > 2 ? " prev" : "")
            }
          ></div>
          <div
            className={
              "addplace_progress_step" +
              (num > 2 ? " complete" : "") +
              (num > 3 ? " prev" : "") +
              (num > 2 ? " next" : "")
            }
          ></div>
          <div
            className={
              "addplace_progress_step" +
              (num > 3 ? " complete" : "") +
              (num > 4 ? " prev" : "") +
              (num > 3 ? " next" : "")
            }
          ></div>
        </div>
        <div className="addplace_progress_numbers">
          <div
            className={
              "addplace_progress_number" + (num > 0 ? " complete" : "")
            }
          >
            1
          </div>
          <div
            className={
              "addplace_progress_number" + (num > 1 ? " complete" : "")
            }
          >
            2
          </div>
          <div
            className={
              "addplace_progress_number" + (num > 2 ? " complete" : "")
            }
          >
            3
          </div>
          <div
            className={
              "addplace_progress_number" + (num > 3 ? " complete" : "")
            }
          >
            4
          </div>
        </div>
      </div>
    );
  }

  selectStaffRating(event) {
    this.setState({
      staffRating: event.target.checked
    });
  }

  updateStaffRating(rating) {
    this.setState({
      rating
    });
  }

  toggleStairs = name =>
    this.setState({
      stairOptions: {
        ...this.state.stairOptions,
        [name]: !this.state.stairOptions[name]
      }
    });

  toggleFavorite = async isFavorite => {
    await this.props.checkToken(() => {});

    if (this.props.isLoggedIn) {
      await this.controller.addFavorite(this.state.id, isFavorite);

      this.setState({ isFavorite });
    } else {
      this.props.history.push("/signin");
    }
  };

  copyToClipboard = () => {
    const copyText = document.getElementById("copy_link");

    /* Select the text field */
    copyText.select();

    /* Copy the text inside the text field */
    document.execCommand("copy");

    /* Alert the copied text */
    alert("Copied the text: " + copyText.value);
  };

  // TODO: Refactor
  render() {
    const { placeId, files, isFavorite, isShareOpen } = this.state;

    // Allow updates without photos
    const canSubmit =
      placeId ||
      (files &&
        files.length &&
        this.state.picture_tags &&
        this.state.picture_tags.length);

    const isMobile = window.innerWidth <= 768;

    return this.state.mounted ? (
      <div className={"addplace_main " + this.state.step.name}>
        {isShareOpen && (
          <ModalComponent
            closeModal={() => this.setState({ isShareOpen: false })}
          >
            <div className="share_modal">
              <h2>Share A Link to This Place</h2>

              <div>
                <input
                  type="text"
                  id="copy_link"
                  value={`${window.location.protocol}//${window.location.host}/place/${placeId}`}
                />
              </div>
              <div>
                <button className="copy_button" onClick={this.copyToClipboard}>
                  Copy Link
                </button>
              </div>
            </div>
          </ModalComponent>
        )}

        {this.state.id ? <div>Editing place: {this.state.name}</div> : ""}
        <div className="addplace_header"></div>
        <div className="addplace_steps">
          <div
            style={{ display: this.state.step.step === 1 ? "block" : "none" }}
            className="addplace_step_one"
          >
            {this.writeProgress(1)}
            <h2>Add a Place to Review</h2>
            <div className="place_title">
              Search for the place you want to review
            </div>
            <Autocomplete
              ref={node => (this.autocomplete = node)}
              className="input_normal"
              onPlaceSelected={this.setGooglePlace}
              fields={[
                "formatted_address",
                "formatted_phone_number",
                "addressComponents",
                "opening_hours",
                "name",
                "photos",
                "placeId",
                "price_level",
                "rating",
                "website",
                "geometry",
                "url"
              ]}
              types={["establishment"]}
              placeholder="Enter location or place"
              componentRestrictions={{ country: "us" }}
            />
            <div className="message_wrapper">
              {this.state.message ? this.state.message : ""}
              {this.state.exists ? (
                <div className="exists_wrapper">
                  <div className="exists_callout">
                    This place already exists in TravelEZ. You can check it out
                    at:
                  </div>
                  <a
                    target="_blank"
                    href={"/place/" + this.state.exists.place.id}
                  >
                    {this.state.exists.name}
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="button_wrapper">
              <button
                className="primary_button"
                onClick={() => {
                  this.goNext(2);
                }}
              >
                Next
              </button>
            </div>
          </div>

          <div style={{ display: this.state.step.step === 2 ? "" : "none" }}>
            <div>
              {this.writeProgress(2)}
              <h2 className="required">Accessibility Photos (Required)</h2>
              <br />

              <div className="place_input place_title">
                Click Choose Images to Upload and Tag photos of: Entrance,
                Overview of Interior, Tables/Chairs, Bathroom, and any other
                helpful pictures. These photos are the most important
                information to help other TravelEZers make their decision, so
                please take and tag helpful pictures!
                {this.state.pictures.length > 0 && !this.state.uploading ? (
                  <div className="upload_images">
                    <h2>Pictures to upload</h2>
                    <div className="upload_images_wrapper">
                      <AliceCarousel
                        responsive={{
                            0: { items: 1 },
                            250: { items: 1 },
                            500: { items: 2 },
                            720: { items: 3 },
                            1024: { items: 4 }
                          }
                        }
                        buttonsDisabled={true}
                        mouseDragEnabled
                        items={this.state.pictures.map((picture, index) => {
                          return this.drawImage(picture, index);
                        })}
                      ></AliceCarousel>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <ImageUploader
                  ref={node => (this.uploader = node)}
                  withIcon={true}
                  buttonText="Choose images"
                  onChange={this.onDrop}
                  imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
                  maxFileSize={5242880}
                />
              </div>
              <div className="tag_photos_explanation">
                Please tag photos by changing “Tag” to appropriate tag
                before clicking "Next”.
              </div>
              <div className="button_wrapper">
                <button
                  className="secondary_button"
                  onClick={() => {
                    this.goNext(1);
                  }}
                >
                  Back
                </button>
                <button
                  className="primary_button"
                  disabled={!canSubmit}
                  onClick={() => {
                    this.goNext(3);
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>

          <div
            style={{ display: this.state.step.step === 3 ? "" : "none" }}
            className="addplace_step_three"
          >
            {this.writeProgress(3)}
            <h2 className="required">Accessibility Details (Required)</h2>
            <br />
            <div className="place_input place_title">
              What accessibility features does this place provide?
              <Features
                showEntranceFeatures={true}
                showCheckBox={true}
                toggleStairs={this.toggleStairs}
                decreaseStairs={this.decreaseStairs}
                increaseStairs={this.increaseStairs}
                stairs_num={this.state.stairs}
                stairs={true}
                deployable={this.state.stairOptions.deployable}
                entrance={this.state.stairOptions.entrance}
                lift={this.state.stairOptions.lift}
                features={this.state.features}
                toggleFeature={this.toggleFeature}
              />
            </div>
            <div className="button_wrapper">
              <button
                className="secondary_button"
                onClick={() => {
                  this.goNext(2);
                }}
              >
                Back
              </button>
              <button
                className="primary_button"
                onClick={() => {
                  this.goNext(4);
                }}
              >
                Next
              </button>
            </div>
          </div>
          <div style={{ display: this.state.step.step === 4 ? "" : "none" }}>
            {this.state.uploading ? (
              <div className="upload_overlay">
                <div className="progress_wrapper">
                  <h2>Uploading</h2>
                  {this.state.upload.image ? (
                    <div className="image_name">
                      Image {this.state.upload.image}
                    </div>
                  ) : (
                    ""
                  )}
                  <ProgressBar
                    animated
                    striped
                    variant="success"
                    label={
                      this.state.upload.percentCompleted === 100 &&
                      "Validating results. Please wait!"
                    }
                    now={this.state.upload.percentCompleted}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {this.writeProgress(4)}
            <h2>Accessibility Comments & Tips (Optional)</h2>
            <br />
            <div className="place_input">
              <div className="place_title">
                {/* This is your chance to let other TravelEZers know about <br />
                anything that might help in making their plans. */}
                <div className="place_comments">
                  <div>
                    <br />
                    STAFF: How helpful was the staff in fulfilling your needs?
                    <br />
                    <div className="place_comments_rating">
                      <input
                        type="checkbox"
                        defaultChecked={this.state.staffRating}
                        onClick={this.selectStaffRating}
                        name="staff"
                      />{" "}
                      <label>Rate Staff</label>
                      {this.state.staffRating ? (
                        <Rating
                          rating={this.state.rating}
                          onEdited={this.updateStaffRating}
                          editable={true}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <br />
                  <div>
                    COMMENTS: Share any information that will help other
                    TravelEZers decide if this place will meet their
                    accessibility standards. Feel free to make your review fun,
                    and ONLY FOCUS ON ACCESSIBILITY-RELATED INFO (we let Yelpers
                    talk about how tremendous the secret BLT sauce was)!
                    <textarea
                      defaultValue={this.state.comments}
                      ref={node => (this.comments = node)}
                      placeholder="Type your comments here!"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_wrapper">
              <button
                className="secondary_button"
                onClick={() => {
                  this.goNext(3);
                }}
              >
                Back
              </button>
              <button className="primary_button" onClick={this.submitReview}>
                {this.state.placeId ? "Update" : "Add"} Place
              </button>
            </div>
          </div>
          <div
            style={{ display: this.state.step.step === 5 ? "" : "none" }}
            className="addplace_step_five"
          >
            <h2>Your Place Has Been Added!</h2>
            <br />
            <div className="place_input place_title">
              Your place has been added and is ready to be viewed:
            </div>
            <div className="button_wrapper">
              <button
                className="primary_button check_out"
                onClick={this.goToPlace}
              >
                Check It Out!
              </button>
              <button
                className="primary_button check_out"
                onClick={this.goHome}
              >
                Return to home
              </button>
            </div>
            <div className="share_container">
              <div className="place_input share_message">
                Or click one of the icons below to Add to Favorites or Share
                with a Friend:
              </div>
              <div className="share_buttons">
                {isFavorite ? (
                  <div
                    title="Remove from Favorites"
                    className="favorite_button_selected"
                  >
                    <FontAwesomeIcon
                      onClick={() => this.toggleFavorite(false)}
                      icon="heart"
                    />
                  </div>
                ) : (
                  <div title="Add to Favorites" className="favorite_button">
                    <FontAwesomeIcon
                      onClick={() => this.toggleFavorite(true)}
                      icon="heart"
                    />
                  </div>
                )}
                <div title="Share" className="share_button">
                  <FontAwesomeIcon
                    onClick={() => this.setState({ isShareOpen: true })}
                    icon="share-alt"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  }
}
